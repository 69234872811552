import React, { useContext, useState } from "react";
import {CartContext} from '../cart/cart'
import {
  AppBar,
  Toolbar,
  IconButton,
  Badge,
  Fade, 
} from "@material-ui/core";
import { Link } from "gatsby";
import Img from "gatsby-image";
import {
  ShoppingCart,
  Facebook,
  DateRange,
  Menu as Hamburger,
  MenuOpen
} from "@material-ui/icons";
import { useStaticQuery, graphql } from "gatsby";
import useStyles from "./styles";
import MobileMenu from './mobileMenu/mobileMenu'

const Navbar = ({onClick, currentPage}) => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsConfig {
        logo {
          sizes(maxWidth: 65, imgixParams: { fm: "webp" }) {
            ...GatsbyDatoCmsSizes
          }
        }
      }
    }
  `);
  const { cartQuantity, toggleCart } = useContext(CartContext)
  const [menuOpen, setMenuOpen] = useState(false);
  const classes = useStyles();
  return (
    <>
      <AppBar position="fixed" className={classes.appBar}>
        <Fade in timeout={1500}>
        <Toolbar>
          <Link to={"/"}>
            <Img className={classes.logo} fluid={data.datoCmsConfig.logo.sizes} placeholderStyle={{ visibility: "hidden" }}/>
          </Link>
          <div className={classes.grow} />
          <div className={classes.button}>
            { cartQuantity ?
              <Fade in timeout={1000}>
                <IconButton aria-label="Show Cart Items" color="inherit" onClick={toggleCart}>
                  <Badge badgeContent={cartQuantity} color={"secondary"}>
                    <ShoppingCart />
                  </Badge>
                </IconButton>
              </Fade>
              :
              null
            }
            <Link to={"/make-wine"}>
              <IconButton aria-label="Book Appointment" color="inherit">
                <DateRange/>
              </IconButton>
            </Link>
            <a href="https://www.facebook.com/julianaswineboutique/">
              <IconButton aria-label="Facebook Link" color="inherit">
                <Facebook />
              </IconButton>
            </a>
            <IconButton
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              className={classes.menuButton}
              aria-label="Menu"
              color="inherit"
            >
              {menuOpen ? <MenuOpen /> : <Hamburger />}
            </IconButton>
          </div>
        </Toolbar>
        </Fade>
      </AppBar>
      {menuOpen && (
        <Fade in="true">
          <MobileMenu onClick={onClick} currentPage={currentPage} setMenuOpen={setMenuOpen}/>
        </Fade>
      )}
    </>
  );
};

export default Navbar;
