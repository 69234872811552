import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        background:"radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(98,0,21,1) 82%)",
    },
    container:{
        backgroundColor:"white",
        border:"5px solid " + theme.palette.secondary.main,
        padding:"5px",
    },
    title:{
        marginBotto:"25px",
        textAlign:"center"
    },
    video:{
        boxShadow:"0px 0px 5px 0px rgba(0,0,0,0.5)"
    },
    imageContainer:{
        width:"100%",
        backgroundColor:"black",
        display:"flex",
        justifyContent:"center",
        marginBottom:"25px",
        boxShadow:"0px 0px 5px 0px rgba(0,0,0,0.5)",
        [theme.breakpoints.down("xs")]:{
            width:"100%"
        }
    },
    image:{
        width:"400px"
    },
    videoTitle:{
        margin:"25px 0px",
        textAlign:"center"
    },
    videoDescription:{
        marginBottom:"25px",
        textAlign:"center"
    },
    divider:{
        width:"90%",
        height:"1px",
        margin:"25px 5%"
    }
}))