import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme)=>({
    root:{
        position:"relative",
        overflow:"hidden",
    },
    images:{
        maxHeight:"100%",
    },
    media:{
        position:"absolute",
        opacity:0,
        width:"100%",
        transform:"scale(1)",
        transition:"transform 15s, opacity 1s",
        transformOrigin:"top"
    },
    active:{
        opacity:1,
        transform:"scale(1.05)",
    },
    zoomImage:{
        transform:"scale(1.05)",
    },
    text:{
        position:"absolute",
        width:"30%",
        top:"10%",
        textAlign:"center",
        fontFamily:"Anton, sans-serif",
        fontWeight:"[1000]",
        fontSize:"70px",
        color:"white",
        WebkitTextStrokeWidth:"2px",
        WebkitTextStrokeColor:"black",
        textShadow:"1px 1px 5px black",
        [theme.breakpoints.down('md')]:{
            fontSize:"50px"
        },
        [theme.breakpoints.down('sm')]:{
            width:"80%",
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:"30px",
            top:"5%"
        }
    },
    buttons:{
        position:"absolute",
        top:"60%",
        width:"30%",
        display:"flex",
        justifyContent:"center",
        [theme.breakpoints.down('md')]:{
            top:"75%"
        },
        [theme.breakpoints.down('sm')]:{
            width:"80%",
            flexDirection:"row",
            marginLeft:"10%",
            marginRight:"0",
            top:"50%"
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:"30px",
            top:"55%"
        }
    },
    button:{
        padding:"15px 30px",
        margin:"0px 10px",
        backgroundColor:theme.palette.secondary.main,
        color:theme.palette.textSecondary.main,
        transition:'all .25s',
        border:"2px solid white",
        '&:hover':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
            borderColor:theme.palette.textPrimary.main,
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        },
        [theme.breakpoints.down('md')]:{
            marginTop:"15px",
            padding:"10px 15px"
        },
    },
    link:{
        textDecoration:"none"
    },
    left:{
        marginLeft:"15%",
        [theme.breakpoints.down('sm')]:{
            marginLeft:"10%",
            marginRight:"0"
        }
    },
    right:{
        right:"0",
        marginRight:"15%",
        [theme.breakpoints.down('sm')]:{
            marginLeft:"10%",
            marginRight:"0"
        }
    },
    center:{
        marginLeft:"36%",
        marginRight:"36%",
        [theme.breakpoints.down('sm')]:{
            marginLeft:"10%",
            marginRight:"0"
        }
    }
}))