import React from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { KeyboardBackspace } from '@material-ui/icons'
import useStyles from './styles'

const CloseCartButton = ({setCartOpen}) => {
    const classes = useStyles()
    return (
        <Grid item xs={12} md={8}>
            <IconButton onClick={()=>{setCartOpen(false)}}>
                <KeyboardBackspace className={classes.backButton}/>
                <Typography variant={"body2"}>
                    Back
                </Typography>
            </IconButton>
        </Grid>
    )
}

export default CloseCartButton
