import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './styles'

const DeliveryDetails = ({address}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Typography variant={'h5'}>
                Delivery Details
            </Typography>
            <Grid container>
                <Grid item xs={3}>
                    <Typography variant={'body2'}>
                        Delivery Address
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant={'body2'}>
                        {address.apt ? address.address + " Unit# " + address.apt: address.address}
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default DeliveryDetails
