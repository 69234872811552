import React, {useState, useEffect} from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { Products, BigMenu, Navbar } from '../../components'
import ProductSearch from './products/Search/search'
import useStyles from './styles'


const Shop = (props) => {
    const classes = useStyles()
    const data = useStaticQuery(graphql`
    query {
        allDatoCmsProduct(sort: {fields: name, order: ASC}) {
            edges {
                node {
                    id
                    name
                    price
                    salePrice
                    description
                    soldOut
                    taxExempt
                    word{
                        word
                    }
                    image {
                        url
                        sizes(maxWidth: 300, imgixParams: { fm: "webp" }) {
                            ...GatsbyDatoCmsSizes
                        }
                    }
                }
            }
        }
        datoCmsStore {
            backgroundImage{
                sizes(maxWidth: 1920, imgixParams: { fm: "webp" }){
                    ...GatsbyDatoCmsSizes
                } 
            }
        }
        site {
            siteMetadata {
                siteName
            }
        }
    }
  `)
    const [products, setProducts] = useState([])
    const [filter, setFilter] = useState(props.search)
    const filteredProductArrays = (products) => (
        filter.split(" ").map((word)=>(
            products.filter((product)=>(
                product.name.toLowerCase().includes(word.toLowerCase()) 
                // || product.description.toLowerCase().includes(word.toLowerCase())
                || (product.style && product.style.toLowerCase().includes(word.toLowerCase()))
                || product.word.some((w)=>{
                    return w.word && w.word.toLowerCase().includes(word.toLowerCase())
                })
            ))
        ))
    )
    const [filteredByFirstWord, ...filteredByRestOfWords] = filteredProductArrays(products)
    const filteredProducts = filteredByFirstWord.filter((product)=>{
        let inAllArrays = true
        filteredByRestOfWords.forEach((products)=>{
            if(!products.includes(product))inAllArrays = false
        })
        return inAllArrays
    })
    useEffect(()=>{
        data && setProducts(
            data.allDatoCmsProduct.edges.map((node)=>(
                Object.values(node)[0]
            ))
        )
    },[data])
    return (
        <div className={classes.root} {...props}>
            <Navbar onClick={setFilter} currentPage={'/store'}/>
            <BigMenu onClick={setFilter} currentPage={'/store'}/>
            <ProductSearch onChange={setFilter} start={filter}/>
            <Products products={filteredProducts}/>
            </div>
    )
}

export default Shop



