import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme) => ({
    root:{
        width:"100%",
        display:"flex",
        justifyContent:"center",
        backgroundColor:"white",
        borderBottom:"5px solid " + theme.palette.secondary.main,
        zIndex:"5",
    },
    menuRoot:{
        width:"90%",
        fontFamily:"system-ui",
        textAlign:"center",
        margin:"0",
        [theme.breakpoints.down('md')]:{
            // width:"90%"
        },
        [theme.breakpoints.down('sm')]:{
            width:"75%",
            display:"none"
        },
        [theme.breakpoints.down('xs')]:{
            display:"none",
        },
    },
    link:{
        width:"100%",
        display:"inline-block",
        textDecoration:'none',
        color:"black"
    },
    linkContainer:{
        borderBottom:"1px solid #ffffff55",
        minHeight:"30px"
    },
    button:{
        width:"100%",
        height:"100%",
        padding:"15px 25px",
        borderBottom:"5px solid transparent",
        '& .MuiSvgIcon-root':{
            fill:"black"
        },
        '&:hover':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)",
            borderRadius:"0px"
        },
        
    },
    cta:{
        backgroundColor:theme.palette.secondary.main,
        color:theme.palette.textSecondary.main,
        transition:'all .25s',
        border:"1px solid transparent",
        '&:hover':{
            color:theme.palette.secondary.main,
            borderColor:theme.palette.secondary.main,
        }
    },
    active:{
        borderBottom:"5px solid " + theme.palette.secondary.main,
        boxShadow:"0px -1px 1px rgba(0,0,0,0.15)"
    },
    subMenu:{
        position:"absolute",
        top:"50px",
        minWidth:"500px",
        backgroundColor:"white",
        zIndex:"2",
        border:"10px solid white",
        paddingTop:"50px",
        borderTop:"none",
        boxShadow:"0px 3px 5px rgba(0,0,0,0.5)",
        borderRadius: "0px 0px 55% 55%",
        overflow:"hidden",
        '& button':{
            color:"white",
        }
    },
    wine:{
        backgroundColor: theme.palette.secondary.main
    },
}));