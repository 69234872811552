import React, { useEffect, useRef, useState } from 'react'
import { Grid, Typography, Fade} from '@material-ui/core'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import useStyles from './styles'

const ActionImage = ({data}) => {
    const classes = useStyles()
    const [index, setIndex] = useState(0)
    const imagesRef = useRef()
    useEffect(()=>{
        const images = imagesRef.current
        images.children[index].classList.add(classes.active)
        setTimeout(()=>{
            images.children[index].classList.remove(classes.active)
            const nextIndex = index === data.length-1 ? 0 : index + 1
            setIndex(nextIndex)
        },7500)
    },[index, data, classes])
    // const getButtons = () => {
    //     const buttons = 
    //         [data[index].button1, data[index].button2].filter((button)=>(
    //             button !== "none"
    //         ))
    //     return buttons.map((button, key)=>(
    //         <Link 
    //             key={button + '-' + key}
    //             to={
    //                 {'make wine':"/make-some-wine", 'visit store':"/store"}[button]}
    //             className={classes.link}
    //         >
    //             <Button 
    //                 variant={"contained"} 
    //                 color={"primary"}
    //                 className={classes.button}
    //             >
    //             {button.toUpperCase()}
    //             </Button>
    //         </Link>
    //     ))
    // }
    return (
        <Link to={'/store'} state={{searchFor:data[index].storeSearchWord}}>
            <Fade in timeout={1750}>
            <Grid className={classes.root} type="container">
                <div ref={imagesRef} className={classes.images}>
                    {
                        data.map((ai, key)=>{
                            return(
                                <Img 
                                    key={ai.id + '-' + key} 
                                    fluid={ai.image.sizes} 
                                    style={key===data.length - 1 ? {position:"relative"} : {position:"absolute"}} 
                                    className={classes.media}/>)
                        })
                    }   
                </div>
                <Typography className={`${classes.text} ${classes[data[index].textPosition]}`} variant={'h2'}>
                    {data[index].text}
                </Typography>
                {/* <Container className={`${classes.buttons} ${classes[data[index].textPosition]}`}>
                {
                    getButtons().map((button)=>(button))
                }
                </Container> */}
            </Grid> 
            </Fade>
        </Link>
    )
}

export default ActionImage
