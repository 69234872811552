import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        backgroundColor:"rgb(254,254,254)",
        overflowY:"scroll",
        overflowX:"hidden"
    },
    cartContainer:{
        width:"100%",
        backgroundColor:"rgb(254,254,254)"      
    },
    productItem:{
        height:"200px",
        width:"100%",
        backgroundColor:"white",
        padding:"25px"
    },
    productDescription:{
        [theme.breakpoints.down('sm')]:{
            marginLeft:"60px"
        }
    },
    itemList:{
        marginBottom:"50px"
    },
    imageContainer:{
        width:"100%",
        maxHeight:"100%",
        '& > div':{
            height:"100%",
            '& img':{
                objectFit:"contain !important"
            }
        },
        [theme.breakpoints.down('sm')]:{
            position:"absolute",
            width:"50px"
        }
    },
    deleteButton:{
        fill:'#620015'
    },
    backButton:{
        fill:theme.palette.secondary.main,
        marginRight:"10px"
    },
    incrementor:{
        marginTop:"50px",
        width:"125px",
        border:"1px solid rgb(234,234,234)",
        display:"flex",
        justifyContent:"center",
        textAlign:"center",
        alignItems:"center",
        height:"50px",
    },
    price:{
        marginTop:"50px"
    },
    removeButton:{
        fill:"#620015"
    },
    closeButton:{
        padding:"0",
        '& svg':{
            fill:"#620015"
        }
    },
    incrementButton:{
        margin:"0px 10px",
        '& svg':{
            fill:"black"
        }
    },
    shippingInfo:{
        marginTop:"10px",
        fontSize:"12px"
    },
    checkoutButton:{
        marginTop:"25px",
        width:"100%",
        padding:"15px 30px",
        backgroundColor:"#620015",
        color:"white",
        transition:'all .25s',
        border:"2px solid white",
        '&:hover':{
            backgroundColor:"white",
            color:"#620015",
            borderColor:"lightblue",
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        }
    },
    couponButton:{
        backgroundColor:"#620015",
        color:"white",
        transition:'all .25s',
        border:"2px solid white",
        borderRadius:"10px",
        width:"50px",
        height:"80%",
        marginTop:"10px",
        '&:hover':{
            backgroundColor:"white",
            color:"#620015",
            borderColor:"lightblue",
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        }
    },
    error:{
        color:"red",
        height:"15px",
        fontSize:'12px',
        textAlign:'center'
    },
}))