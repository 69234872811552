import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography } from "@material-ui/core";
import Img from "gatsby-image";
import useStyles from "./styles";

const Testimonials = ({ data, logo }) => {
  const [index, setIndex] = useState(1);
  const classes = useStyles();
  const containerRef = useRef();
  useEffect(() => {
    containerRef.current.children[index].classList.add(classes.active)
    const timeout = setTimeout(() => {
        containerRef.current.children[index].classList.remove(classes.active)
        setIndex(index < data.length - 1 ? index + 1 : 0);
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, [index, setIndex, data, containerRef, classes]);
  return (
    <>
      <Grid
        container
        className={classes.testimonials}
        direction="column"
        alignItems="center"
        ref={containerRef}
      >
        {data.map((quote) => (
          <React.Fragment key={quote.author}>
            <Grid item className={classes.item} xs={10} lg={8}>
              <Typography className={classes.quote} variant="h3">
                {quote.quote}
              </Typography>
              <Typography className={classes.author} variant="h5">
                {quote.author}
              </Typography>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Grid container justify="center"> 
        <Grid item className={classes.logo}>
            <Img fluid={logo} />
        </Grid>
      </Grid>
    </>
  );
};

export default Testimonials;
