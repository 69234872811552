import React, {useState, useEffect} from 'react'
import { Grid, Button, Typography, InputLabel, FormControl, Input } from '@material-ui/core'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { HighlightOff } from '@material-ui/icons'
import useStyles from './styles'

const SignUpForm = () => {
    const classes = useStyles()
    const [signUpFormOpen, setSignUpFormOpen] = useState(false)
    const [formStatus, setFormStatus] = useState({
        error:null,
        stage:"default"
    })
    const validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    const handleSubmitEmail = (e) => {
        e.preventDefault()
        const value = e.currentTarget.parentNode && e.currentTarget.parentNode.querySelector('input') ?
            e.currentTarget.parentNode.querySelector('input').value :
            null
        if(value){
            const valid = validateEmail(value)
            if(!valid){
                setFormStatus(Object.assign({}, formStatus, {error:"Sorry that is not a valid email address"}))
            }
            else{
                addToMailchimp(value).then((response)=>{
                    setFormStatus(Object.assign({}, formStatus, {stage:"confirmation"}))
                    setTimeout(()=>{
                        setSignUpFormOpen(false)
                    },2500)
                })
            }
        } else {
            if(value === "")setFormStatus(Object.assign({}, formStatus, {error:"Please enter your email address"}))
        }
    }
    useEffect(()=>{
        const timeout = setTimeout(()=>{
            setSignUpFormOpen(true)
        }, 5000)
        return ()=>{clearTimeout(timeout)}
    },[setSignUpFormOpen])
    const Form = () => 
    (
        <Grid container justify="center" alignItems="center" className={classes.root}>
            <Grid item className={classes.popup}>
                <Grid container className={classes.inner} direction="column" alignItems="center">
                    <Grid item xs={12} className={classes.closeButton}>
                        <Button onClick={()=>{setSignUpFormOpen(false)}}>
                            <HighlightOff/>
                        </Button>
                    </Grid>
                    <Grid item xs={8} className={classes.title}>
                        <Typography variant={"h5"}>
                            Sign up to our newsletter and access to special deals!
                        </Typography>
                    </Grid>
                    <Grid item xs={10} className={classes.input}>
                        <FormControl className={classes.formControl}>
                            <InputLabel htmlFor={"email"}>Email Address</InputLabel>
                            <Input name={"email"} id={"email"}/>
                            {
                                formStatus.error && (
                                    <span className={classes.error}>
                                        {formStatus.error}
                                    </span>
                                )
                            }
                            <Button className={classes.submit} onClick={handleSubmitEmail} type="submit">
                                Sign Up
                            </Button>
                     </FormControl>
                    </Grid>
                    <Grid className={classes.privacy} item xs={12}>

                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
    const Confirmation = () => (
        <Grid container justify="center" alignItems="center" className={classes.root}>
            <Grid item className={classes.popup}>
                <Grid container className={classes.inner} alignItems="center" justify="center">
                    <Grid item xs={12}>
                        <Typography varaint={'h4'} className={classes.thankyou}>
                            THANK YOU!
                        </Typography>
                        <Typography varaint={'h5'} className={classes.thankyou}>
                            Be sure to check your email often for access to exclusive deals
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>      
    )
    return (
        <>
            { signUpFormOpen && formStatus.stage === 'default' && <Form/>}
            { signUpFormOpen && formStatus.stage === "confirmation" && <Confirmation/>}
        </>
    )
}

export default SignUpForm

