import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{

    },
    fieldsContainer:{
        alignContent:"center",
        height:"600px"
    },
    field:{
        margin:"5px 0px",
        width:'325px',
        textAlign:'center',
        '& div fieldset.MuiOutlinedInput-notchedOutline':{
            borderColor:theme.palette.secondary.main
        },
        '& label':{
            color:theme.palette.secondary.main
        },
        '& input':{
            textAlign:'center',
            color:theme.palette.secondary.main
        },
    },
    submitButtonContainer:{
        display:'flex',
        flexDirection:'column',
        alignItems: 'center',
    },
    submitButton:{
        background:theme.palette.secondary.main,
        color:theme.palette.textSecondary.main,
        border:'1px solid white',
        width:'325px',
        padding:'15px 10px', 
        marginTop:'50px',
        '&:hover':{
            color:theme.palette.secondary.main,
            border:'1px solid ' + theme.palette.secondary.main
        }
    },
}))