import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    formControl:{
        width:"100%",
        '& input':{

        }
    },
    error:{
        color:"red",
        fontSize:"12px"
    }
}))