import React from 'react'
import { Grid, Typography, Divider, Container, Hidden } from '@material-ui/core'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import useStyles from './styles'

const ServiceSection = ({data}) => {
    const classes = useStyles()
    const dataArr = [{
            id:0,
            image:data.stepOneImage, 
            title:data.stepOneTitle, 
            description:data.stepOneDescription,
            linkText:"Wine Menu",
            linkTo:'/wine-list'
        },
        {
            id:1,
            image:data.stepTwoImage, 
            title:data.stepTwoTitle, 
            description:data.stepTwoDescription,
            linkText:"Make Wine",
            linkTo:'/make-wine'
        },
        {
            id:2,
            image:data.stepThreeImage, 
            title:data.stepThreeTitle, 
            description:data.stepThreeDescription,
            linkText:"Bottle Wine",
            linkTo:'/make-wine'
        }
    ]
    return (
        <>
            <Container className={classes.sectionTitleContainer}>
                <Typography className={classes.sectionTitle} variant={"h4"}>
                    Make wine on premise at Kenora Craft using three simple steps
                </Typography>
            </Container>
            <Grid className={classes.root} container  direction="row" spacing={5}>  
                {dataArr.map((item)=>(
                    <Grid key={item.id} item className={classes.item} sm={12} md={4}>
                        <Hidden smUp><Divider className={classes.divider}/></Hidden>
                        <Img className={classes.media} fluid={item.image.sizes}/>
                        <Typography className={classes.title} variant={'h4'}>
                            {item.title}
                        </Typography>
                        <Typography className={classes.body} variant={'body2'}>
                            {item.description}
                        </Typography>
                        <Link className={classes.link} to={item.linkTo}>{item.linkText.toUpperCase()}</Link>
                    </Grid>
                ))}
                
            </Grid>
            {/* <Container className={classes.buttonContainer}>
                <Button 
                    variant={"contained"} 
                    color={"primary"}
                    className={classes.button}
                    >
                    GET CRAFTING
                </Button>
            </Container> */}
        </>
    )
}

export default ServiceSection
