import React, { useState } from 'react'
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails, Fade } from '@material-ui/core'
import Img from 'gatsby-image'
import useStyles from './styles'

const WineTroubleshooting = ({data}) => {
    const classes = useStyles()
    const { title, description, issues, evaluations } = data
    const [expanded, setExpanded] = useState(null)
    const Title = ({variant, text}) => (
        <Grid className={classes.title} item xs={12}>
            <Typography variant={variant}>
                {text}
            </Typography>
        </Grid>
    )
    const Instructions = () => (
        <Grid className={classes.instructions} item xs={10} md={6}>
            <Typography variant={"body2"}>
                {description}
            </Typography>
        </Grid>
    )
    const SensoryEvaluation = () => (
        evaluations.map((evaluation)=>(
            <>
                <Grid className={classes.sensory} item xs={12}>
                    <Grid container justify="center">                 
                        <Grid item xs={11} md={6}>
                            <Typography className={classes.evalTitle} variant={"h6"}>
                                {evaluation.title}
                            </Typography>
                            {
                                evaluation.evaluations.map((ev)=>(
                                    <Typography className={classes.evalBody} variant={"body2"}>
                                        {ev}
                                    </Typography>
                                ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </>
        ))
    )
    return (
        <Fade in timeout={1000}>
            <Grid container justify="center" className={classes.root}>
                <Grid item xs={12} sm={10} md={8}>
                    <Img className={classes.image} fluid={data.image.sizes}/>
                </Grid> 
                <Grid item xs={12} sm={10} md={8}>
                    <Grid className={classes.container} container justify="center">
                        <Title variant={"h4"} text={title}/>
                        <Instructions/>
                        <Title variant={"h6"} text={"Sensory Evaluation"}/>
                        <SensoryEvaluation/>
                        <Title variant={"h6"} text={"Common Issues"}/>
                        <Grid item xs={11} md={10} lg={8}>
                        {
                            issues.map((issue)=>(
                                <Accordion square expanded={expanded===issue.id} onClick={()=>{setExpanded(expanded===issue.id ? null : issue.id)}}>
                                    <AccordionSummary className={classes.accordion} >
                                        <Typography variant={"body1"}>
                                            {issue.issue}
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.collapseContent}>
                                        <Grid container justify="center">
                                            <Title variant={"body1"} text={"Possible Causes & Cues"}/>
                                        {
                                            issue.causes.map((cause)=>(
                                                <Grid className={classes.cc} item xs={10}><Typography variant={"body2"}>{cause}</Typography></Grid>
                                            ))
                                        }
                                        </Grid>
                                        <Grid container justify="center">
                                            <Title variant={"body1"} text={"Corrective Actions"}/>
                                        {
                                            issue.corrections.map((correction)=>(
                                                <Grid className={classes.cc} item xs={10}>{correction}</Grid>
                                            ))
                                        }
                                        </Grid>
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    )
}

export default WineTroubleshooting
