import React from 'react'
import Img from 'gatsby-image'
import { StructuredText } from "react-datocms"
import { Grid, Typography, Hidden, Fade } from '@material-ui/core'
import { Navbar } from '../../components'
import useStyles from './styles'

const BeerCrafting = ({data}) => { console.log("DATA", data)
    const classes = useStyles()
    return (
        <Fade in timeout={1000}>
            <Grid container className={classes.root}>
                <Navbar/>
                <Hidden smDown><Grid item md={1} lg={2}></Grid></Hidden>
                <Grid className={classes.container} item sm={12} md={10} lg={8}>
                    <Grid container className={classes.imageContainer}>
                        <Grid item className={classes.overlay}>
                            <Typography className={classes.title} variant={'h4'}>
                                {data.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Img className={classes.image} fluid={data.headerImage.sizes}/>
                        </Grid> 
                    </Grid>
                    <Grid container className={classes.articleContainer} justify="center">
                        <Grid item xs={10}>
                            <StructuredText data={data.article}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Fade>
    )
}

export default BeerCrafting
