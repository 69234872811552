import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        background:"radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(98,0,21,1) 82%)",
        paddingBottom:"25px"
    },
    container:{
        backgroundColor:"white",
        border:"5px solid " + theme.palette.secondary.main,
        paddingBottom:"25px"
    },
    title:{
        margin:"25px 0px",
        textAlign:"center",
        '& h6':{
            color:theme.palette.secondary.main
        },
        '& h4':{
            color:theme.palette.secondary.main
        }
    },
    instructions:{
        textAlign:"center",
        marginBottom:"25px"
    },
    evalBody:{
        wordBreak:"break-word"
    },
    sensory:{
        marginTop:"25px",
    },
    evalTitle:{
        marginBottom:"5px",
        textAlign:"center"
    },
    collapseContent:{
        flexDirection:"column"
    },
    cc:{
        marginBottom:"5px"
    },
    accordion:{
        border:"1px solid " + theme.palette.secondary.main,
        color:theme.palette.secondary.main,
        marginTop:"5px"
    }
}))