import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database' // importing the auth module as an example

// Firebase web config
// const config = {
//     apiKey: "AIzaSyBzY5lwUx0tERrq3e7oN_QBzW54DK9z7DU",
//     authDomain: "kcwab-cb83a.firebaseapp.com",
//     projectId: "kcwab-cb83a",
//     storageBucket: "kcwab-cb83a.appspot.com",
//     messagingSenderId: "699694628563",
//     appId: "1:699694628563:web:08f8412b345d879e4b1e26"
// }

var config = {
  apiKey: "AIzaSyC9aHqksEePtOPQs_P1aJ7R8tKgyg7fmJw",
  authDomain: "kenora-craft-wine-and-beer.firebaseapp.com",
  databaseURL: "https://kenora-craft-wine-and-beer-default-rtdb.firebaseio.com",
  projectId: "kenora-craft-wine-and-beer",
  storageBucket: "kenora-craft-wine-and-beer.appspot.com",
  messagingSenderId: "854543795222",
  appId: "1:854543795222:web:27ff1c716cd16e8a40a137"
};

let instance = null;

export default function getFirebase() {
  if (typeof window !== 'undefined') {
    if (instance) return instance;
    instance = firebase.initializeApp(config);
    return instance;
  }

  return null;
}