import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    formControl:{
        width:"100%",
        '& input':{

        }
    },
    shippingOption:{
        '& span':{
            fontSize:"14px"
        },
        '& svg':{
            color:theme.palette.secondary.main
        }
    },
    radioButton:{
            '& svg':{
            color:"#620015"
        }
    }
}))