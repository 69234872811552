import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Grid, Typography, Fade, Button } from '@material-ui/core'
import useStyles from './styles'

const Footer = () => {
    const data = useStaticQuery(graphql`
    query {
      datoCmsConfig {
        logo {
          sizes(maxWidth: 100, imgixParams: { fm: "png" }) {
            ...GatsbyDatoCmsSizes
          }
        }
      }
      datoCmsFooter {
        customLabelPrompt
        customLabelButtonImage{
          sizes(maxWidth:300, imgixParams:{ fm: "webp"}){
            ...GatsbyDatoCmsSizes
          }
        }
      }
    }
  `);
    const classes = useStyles()
    return (<Fade in timeout={1500}>
        <Grid container className={classes.root}>
            
                <Grid className={classes.section} item xs={12} md={4}>
                  <Typography  className={`${classes.text} ${classes.borderBottom}`} variant="h6">Hours</Typography>
                    <Typography  className={classes.text} variant="h6">Mon-Fri: 10:00am - 5:30pm</Typography>
                    <Typography  className={classes.text} variant="h6">Sat: 10:00am - 2:00pm</Typography>
                </Grid>
                <Grid className={classes.section} item xs={12} md={4}>
                    <Img className={classes.logo} fluid={data.datoCmsConfig.logo.sizes}/>
                    <Typography className={classes.text} variant={"h6"}>
                        Kenora Craft Wine and Beer
                    </Typography>
                    <Typography className={classes.text} variant={"body2"}>
                        608 B, 4th Street - {'(Back of Building)'}
                    </Typography>
                    <Typography className={classes.text} variant={"body2"}>
                        Keewatin, Ontario
                    </Typography>
                    <Typography className={classes.text} variant={"body2"}>
                        Canada, ON P0X1C0
                    </Typography>
                </Grid>
                <Grid  className={classes.section} item xs={12} sm={4}>
                  <Button className={classes.footerContactButton}>
                    <Typography className={classes.text} variant={"body2"}>
                        <a className={classes.phoneLink} href="tel://+8074678581">Call Us: (807) 467-8581</a>
                    </Typography>
                  </Button>
                  <Button className={classes.footerContactButton}>
                      <Typography className={classes.text} variant={"body2"}>
                          <a className={classes.phoneLink} href="sms:+8074678581">Text Us: (807) 467-8581</a>
                      </Typography>
                    </Button>
                    <Button className={classes.footerContactButton}>
                      <Typography className={classes.text} variant={"body2"}>
                        <a className={classes.emailLink} href="mailto:diy@kenoracraft.ca">Email Us: diy@kenoracraft.ca</a>
                      </Typography>
                    </Button>
                </Grid>
                <Grid className={classes.customLabel} item xs={12}>
                  <a href="https://www.stoneycreekwinepress.com/"> 
                    <Img className={classes.customLabelButton} fluid={data.datoCmsFooter.customLabelButtonImage.sizes} loading="lazy"/>
                  </a>
                  <Typography className={classes.text} variant={"body2"} color={"secondary"} align="center">
                    {data.datoCmsFooter.customLabelPrompt}
                  </Typography>
                </Grid>
                <Grid className={classes.copyright} item xs={12}>
                  <Typography variant={"body1"} align={"center"}>
                    &copy; 2021 Kenora Craft Wine and Beer
                  </Typography>
                </Grid>
        </Grid></Fade>
    )
}

export default Footer
