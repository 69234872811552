import React from 'react'
import useStyles from './styles'
import {
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
  } from "@material-ui/core";
  import { Field } from "react-final-form";

const RadioInput = ({field}) => {
    const classes = useStyles()
    return (
        <Field name={field.id} validate={field.validate}>
            {({ input, meta})=>(
                <FormControl component="fieldset">
                <FormLabel component="legend">{field.label}</FormLabel>
                <RadioGroup aria-label={field.radioAriaLabel} name={field.radioName} onChange={field.onChange}>
                    {
                        field.radioButtons.map((button)=>(
                            <FormControlLabel className={classes.shippingOption} key={button.id} value={button.value} control={<Radio className={classes.radioButton}/>} label={button.label}/>
                        ))
                    }
                </RadioGroup>
                </FormControl>
            )}
        </Field>  
    )
}

export default RadioInput