import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root:{
        background:"radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(98,0,21,1) 82%)",
        minHeight:"800px"
    },
    store:{
        width:"100%",
        minHeight:"100vh",
        display:"inline-block"
    }
}));