import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    root:{
        
    },
    form:{
        border:"4px solid " + theme.palette.secondary.main,
        borderRadius:"10px",
        width:"100%",
        backgroundColor:"#ffffff",
        padding:"5px 50px",
        marginTop:"25px"
    },
    label:{
        padding:"5px 50px",
    },
    addSearch:{
        minWidth:"5px",
        padding:"10px 5px",
        backgroundColor:"white",
        borderRadius:"0px",
        marginTop:"5px"
    }
}));