import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 0;

export default makeStyles((theme) => ({
  appBar: {
    boxShadow: 'none',
    borderBottom: '1px solid ' + theme.palette.secondary.main,
    height:'75px',
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  title: {
    flexGrow: 1,
    alignItems: 'center',
    display: 'flex',
    textDecoration: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  grow: {
    flexGrow: 1,
  },
  mobMenu:{
    border:"2px solid " + theme.palette.secondary.main,
    backgroundColor:"black",
    color:"white",
    paddingBottom:"5px",
    borderBottomWidth:"10px",
    borderTopWidth:"10px",
    position:"fixed",
    zIndex:1,
  },
  menuItemContainer:{
    border:"1px double " + theme.palette.secondary.main,
    justifyContent:"center"
  },
  menuItem:{
      textAlign:"center",
      textDecoration:"none",
      cursor:"pointer"
    },
  menuLink:{
    textDecoration:"none",
    padding:"15px 0px",
    color:"white",
    fontFamily:"Roboto"
  },
  logo:{
    width:"65px",
    marginTop:"5px"
  }
}));