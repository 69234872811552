import React from 'react'
import { StructuredText } from "react-datocms";
import { Grid, Fade } from '@material-ui/core'
import Img from 'gatsby-image'
import { BigMenu, Navbar } from '../index'
import useStyles from './styles'

const TextPage = ({image, structuredText, to}) => {
    const classes = useStyles()
    return (
        <>
            <Navbar/>
            <BigMenu currentPage={to}/>
            <Fade in timeout={1000}>
                <Grid container className={classes.container} justify="center">
                    <Grid item lg={8} md={10} xs={11} className={classes.imageContainer} >
                        <Img sizes={image.sizes}/>
                    </Grid>
                    <Grid item lg={8} md={10} xs={11} className={classes.dataContainer} >
                        <StructuredText data={structuredText}/>
                    </Grid>
                </Grid>
            </Fade>
        </>
    )
}

export default TextPage
