import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        width:"100%",
        backgroundColor:"white",
        padding:"10px 50px 10px 25px",
        marginBottom:"5px"
    },
    total:{
        float:"right"
    }
}))