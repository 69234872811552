import React, {useState, useEffect} from 'react'
import { loadSquareSdk } from '../../utilities/paymentForm'
import PaymentForm from './paymentForm/paymentForm'

const Checkout = ({subtotal, discount, tax, total, setStage, products, user}) => {
    const [squareStatus, setSquareStatus] = useState(null)
    useEffect(()=>{
        loadSquareSdk()
        .then(()=>{setSquareStatus("SUCCESS")})
        .catch(()=>{setSquareStatus("ERROR")})
    },[])
    return (
        <>
            {
                squareStatus === "ERROR" && "Oops something went wrong, please try again later"
            }
            {
                squareStatus === "SUCCESS" && <PaymentForm products={products} discount={discount} subtotal={subtotal} tax={tax} total={total} setStage={setStage} paymentForm={window && window.SqPaymentForm} user={user}/>
            }
        </>
    )
}

export default Checkout
