import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './styles'

const OrderDetails = ({products}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
        <Typography variant={"h6"}>
            Order Details
        </Typography>
        {
            <Grid container>
            {products.map((product)=>(
                <React.Fragment key={product.name}>
               <Grid item xs={3}>
                    <Typography variant={"body2"}>
                        {product.quantity}
                    </Typography>
                </Grid>
                <Grid item xs={7}>
                    <Typography variant={"body2"}>
                        {product.name}
                    </Typography>
                </Grid>
                <Grid className={classes.total} item xs={2}>
                    <Typography variant={"body2"}>
                        {(parseFloat(product.price) * parseFloat(product.quantity)).toFixed(2)}
                    </Typography>
                </Grid>
                </React.Fragment>
            ))}
            </Grid>
        }
        </div>
    )
}

export default OrderDetails
