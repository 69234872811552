import React, {useState} from 'react'
import DateFnsUtils from '@date-io/date-fns';
import { Typography, Grid, TextField, Button } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker, DatePicker } from '@material-ui/pickers';
import useFirebase from "./../../hooks/useFirebase";
import useValidate from './useValidate'
import useStyles from './styles.js'

const AppointmentScheduler = ({
    minTime,
    maxTime, 
    timeArr,
    prompt,
    name=true, 
    email=true, 
    phone=true,
    appointment=true, 
    birth=false,
    addToSubmit=null}) => 
    { 
        const {isRequired, emailFormat, nineteenOrOver, bookedWithinHours, phoneFormat} = useValidate()
        const classes = useStyles()
        const firebase = useFirebase()
        const defaultDate = new Date()
        defaultDate.setHours(12)
        defaultDate.setMinutes(0)
        const [selectedDate, handleDateChange] = useState(defaultDate);
        const [selectedBirthDate, handleBirthDateChange] = useState(defaultDate)
        const [appointmentDialogOpen, setAppointmentDialogOpen] = useState(false)
        const [birthDateDialogOpen, setBirthDateDialogOpen] = useState(false)
        const [booked, setBooked] = useState(null)
        const [errors, setErrors] = useState({submit:null, name:null, email:null, date:null, "birth-date":null})
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const withOrdinal = (num) => {
            const remainderDividedByTen = num % 10
            const remainderDividedByHundred = num % 100
            if (remainderDividedByTen === 1 && remainderDividedByHundred !== 11) {
                return num + "st";
            }
            if (remainderDividedByTen === 2 && remainderDividedByHundred !== 12) {
                return num + "nd";
            }
            if (remainderDividedByTen === 3 && remainderDividedByHundred !== 13) {
                return num + "rd";
            }
            return num + "th";
        }
        const validateInput = (e, validation) => {
            const { name } = e.currentTarget
            const errorsCopy = Object.assign({}, errors)
            const isValid = validation.map((validate)=>
            {
                return validate(e.currentTarget)
            })
            .filter((result)=>(
                result !== null
            ))
            errorsCopy[name] = isValid.length ? isValid[0] : null
            setErrors(errorsCopy)
        }
        const areSameDate = (date, compareTo) => (
            date.getFullYear() === compareTo.getFullYear()
            && 
            date.getMonth() === compareTo.getMonth()
            &&
            date.getDate() === compareTo.getDate()
        )
        const handleSubmit = (e) =>{
            e.preventDefault()
            const inputs = e.target.querySelectorAll('input')
            const submitData = {creationTime:(new Date()).getTime()}
            inputs.forEach((input)=>{
                submitData[input.name] = input.value
            })
            console.log("SUBMIT DATA", submitData)
            const emptyFieldDetected = Object.values(submitData).includes("")
            const errorsDetected = Object.values(errors).find(e=>e!==null)
            if(emptyFieldDetected){
                setErrorMessage('submit', 'Please complete the form before booking')
            }
            if(errorsDetected){
                setErrorMessage('submit', 'Please resolve errors before booking')
            }
            if(!emptyFieldDetected && !errorsDetected){ 
                const data = addToSubmit && addToSubmit.product ? {...submitData, ...addToSubmit} : {...submitData}
                console.log("DATA", data)
                firebase
                    .database().ref('appointments/').push(
                        data
                    ).then(()=>{
                        setBooked(data)
                    }).catch((error)=>{
                        setErrorMessage('submit', 'Something went wrong, please try again later')
                    }) 
            }
        }
        const renderLabel = 
            (date, disableMinutes=false, disableYear=true) => {
                const monthDay =  `${monthNames[date.getMonth()]} ${withOrdinal(date.getDate())}`
                const hours = date.getHours()
                const time = !disableMinutes ? `${hours > 12 ? hours - 12 : hours}:${date.getMinutes().toString().padStart(2,'0')}` : ``
                const year = !disableYear ? `${date.getFullYear()}` : ``
                const aMPM = !disableMinutes ? hours < 12 ? 'am' : 'pm' : ''
                return(
                    areSameDate(date, defaultDate)
                    ?
                    ""
                    :
                    `${monthDay} ${year}${time}${aMPM}`
                )
            }
        const TextFieldComp = (props) =>(
            <TextField
            name={props.name}
            variant={"outlined"}
            className={props.className}
            InputProps={props.InputProps}
            disabled={props.disabled}
            error={props.error}
            helperText={props.helperText}
            onChange={props.onChange}
            value={props.value}
            label={props.label}
            onClick={props.onClick}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            />
        );
        const setErrorMessage = (name, value) => {
            const errorsCopy = Object.assign({}, errors)
            errorsCopy[name] = value
            setErrors(errorsCopy)
        }
        return (
        booked === null ?
            <form className={classes.root} id="appointment-form" onSubmit={handleSubmit}>
                <Grid className={classes.container} container justify="center" alignItems="center" spacing={1}>
                    <Grid style={{marginBottom:'25px'}} item xs={12}>
                        <Typography variant="h5">
                            {prompt}
                        </Typography>
                    </Grid>
                    {
                        name && 
                        <Grid item xs={12}>
                            <TextField
                            variant="outlined" 
                            className={classes.field} 
                            name="name" id="name" 
                            label="Name" 
                            helperText={errors.name} 
                            error={errors.name} 
                            onFocus={e=>{setErrorMessage('submit', null)}}
                            onBlur={e=>{validateInput(e, [isRequired])}}
                            />
                        </Grid>
                    }
                    {
                        email &&
                        <Grid item xs={12}>
                            <TextField 
                                variant="outlined" 
                                className={classes.field}  
                                name="email" 
                                id="email" 
                                label="Email"
                                helperText={errors.email}
                                error={errors.email}
                                onFocus={e=>{setErrorMessage('submit', null)}}
                                onBlur={e=>{validateInput(e, [isRequired, emailFormat])}}
                            />
                        </Grid>
                    }
                    {
                        phone && 
                        <Grid item xs={12}>
                            <TextField
                            variant="outlined" 
                            className={classes.field} 
                            name="phone" id="phone" 
                            label="Phone" 
                            helperText={errors.phone} 
                            error={errors.phone} 
                            onFocus={e=>{setErrorMessage('submit', null)}}
                            onBlur={e=>{validateInput(e, [isRequired, phoneFormat])}}
                            />
                        </Grid>
                    }
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        {
                            appointment &&
                            <Grid item xs={12}>
                                <DateTimePicker 
                                    name={"date"}
                                    className={classes.field} 
                                    variant="dialog" 
                                    TextFieldComponent={(props)=>TextFieldComp(props)}
                                    label="Appointment Date"
                                    value={selectedDate} 
                                    labelFunc={renderLabel}
                                    minutesStep={15} 
                                    color="secondary"
                                    onChange={
                                        (date)=>{
                                            validateInput({
                                                currentTarget:{
                                                    name:'date', 
                                                    value:areSameDate(date, defaultDate) 
                                                        ? "" 
                                                        : date.toString(),
                                                    minTime:minTime,
                                                    maxTime:maxTime,
                                                    timeArr:timeArr
                                                    }
                                                },
                                                [isRequired, bookedWithinHours])
                                            handleDateChange(date)
                                        }
                                    }           
                                    open={appointmentDialogOpen}
                                    onFocus={()=>{setAppointmentDialogOpen(true);setErrorMessage('submit', null)}}
                                    onClose={()=>{setAppointmentDialogOpen(false)}
                                    }
                                    helperText={errors.date}
                                    error={errors.date}
                                />
                            </Grid>
                        }
                        {
                            birth &&
                            <Grid item xs={12}>
                                <DatePicker 
                                    name={"birth-date"}
                                    className={classes.field} 
                                    variant="dialog"
                                    value={selectedBirthDate} 
                                    TextFieldComponent={(props)=>TextFieldComp(props)}
                                    openTo="year" 
                                    label="Verify Birth Date"
                                    labelFunc={date=>renderLabel(date, true, false)}
                                    disableFuture 
                                    onChange={
                                        (date)=>{
                                            validateInput(
                                                {
                                                    currentTarget:{
                                                        name:'birth-date', 
                                                        value:areSameDate(date, defaultDate) 
                                                            ? "" 
                                                            : date.toString()}
                                                },
                                                [isRequired, nineteenOrOver]
                                            )
                                            handleBirthDateChange(date)
                                        }
                                    }
                                    open={birthDateDialogOpen}
                                    onFocus={()=>{setBirthDateDialogOpen(true);setErrorMessage('submit', null)}}
                                    onClose={()=>{setBirthDateDialogOpen(false)}}
                                    helperText={errors["birth-date"]}
                                    error={errors["birth-date"]}
                                />
                            </Grid>
                        }
                        <Grid className={classes.submitButtonContainer} item xs={12}>
                            <Button color={"secondary"} className={classes.submitButton} type="submit">Book</Button>
                            <Typography className={classes.error} variant="body2" textAlign="center">{ errors.submit }</Typography>
                        </Grid>
                    </MuiPickersUtilsProvider>     
                </Grid>
            </form>
            :
            <Grid className={`${classes.container} ${classes.root}`} container justify="center" alignItems="center" spacing={1}>
                <Grid style={{marginBottom:'25px'}} item xs={12}>
                    <Typography variant="h5">
                        Your Appointment has been booked!
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">{`${renderLabel(selectedDate)}`}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">An email confirmation has been sent to {`${booked.email}`}</Typography>
                </Grid>
                <Grid style={{marginBottom:'25px'}} item xs={12}>
                    <Typography variant="h5">
                        Thank you, see you soon
                    </Typography>
                </Grid>
            </Grid>
        )
    }

export default AppointmentScheduler
