import React from 'react'
import { Grid, TextField, Button } from '@material-ui/core'
import useFirebase from "./../../../hooks/useFirebase"
import useStyles from './styles'

const Gate = ({setUser}) => { 
    const classes = useStyles()
    const firebase = useFirebase();
    const handleSubmit = (e) => { 
        e.preventDefault()
        if (!firebase.auth()) return;
        const inputs = e.target.querySelectorAll('input')
            const submitData = {}
            inputs.forEach((input)=>{
                submitData[input.name] = input.value
        })
        firebase
          .auth()
          .signInWithEmailAndPassword(submitData.email, submitData.password)
          .then((userCredentials) => {
            setUser(userCredentials)
          })
          .catch((error)=>{
              console.log("ERROR", error)
            //   const code = error.code.split('/')[1]
          })
    }
    const nameField = () => (
        <TextField
            className={classes.field}
            variant="outlined" 
            name="email" id="email" 
            label="Username" 
        />
    )
    const passwordField = () => (
        <TextField
            className={classes.field}
            variant="outlined"
            type="password"
            name="password"
            id="password"
            label="Password"
        />
    )
    return (
        <form className={classes.root} onSubmit={handleSubmit}>
            <Grid container className={classes.fieldsContainer} alignItems="center" justify="center" direction="column">
                <Grid 
                    item 
                    component={nameField}
                />
                <Grid 
                    item
                    component={passwordField}
                />
                <Grid 
                    className={classes.submitButtonContainer} 
                    item
                >
                    <Button color={"secondary"} className={classes.submitButton} type="submit">Login</Button>
                    {/* <Typography className={classes.error} variant="body2" textAlign="center">{ errors.submit }</Typography> */}
                </Grid>
            </Grid>
        </form>
    )
}

export default Gate
