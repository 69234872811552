import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        width:"90%",
        marginLeft:"5%",
        marginTop:"5px"
    },
    sectionTitleContainer:{
        marginTop:"30px",
        textAlign:"center",
    },
    sectionTitle:{
        fontFamily:"Lato",
        color:theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]:{
            fontSize:'20px'
        }
    },
    item:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        fontFamily:"IBM Plex Sans",
    },
    media:{
        height:"300px",
        width:"300px",
        marginBottom:"25px",
        [theme.breakpoints.down('lg')]:{
            height:"280px",
            width:"280px"
        },
        [theme.breakpoints.down('xs')]:{
            height:"150px",
            width:"150px"
        },
    },
    title:{
        marginBottom:"20px",
        fontFamily:"Anton",
        color:theme.palette.secondary.main,
        [theme.breakpoints.down('lg')]:{
            fontSize:"23px"
        }
    },
    body:{
        width:"80%",
        textAlign:"center",
        fontSize:"17px",
        marginBottom:"15px",
        minHeight:"100px",
        [theme.breakpoints.down('lg')]:{
            width:"90%"
        }
    },
    buttonContainer:{
        textAlign:"center",
        marginTop:"30px",
        marginBottom:"40px"
    },
    link:{
        padding:"15px 30px",
        margin:"10px 10px",
        marginBottom:"25px",
        backgroundColor:theme.palette.secondary.main,
        color:theme.palette.textSecondary.main,
        transition:'all .25s',
        border:"2px solid white",
        borderRadius:"0px 0px 10px 10px",
        textDecoration:'none',  
        '&:hover':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
            borderColor:theme.palette.textPrimary.main,
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        },
        [theme.breakpoints.down('xs')]:{

        },
    },
    divider:{
        height:"5px",
        width:"100%",
        marginBottom:"25px",
        opacity:"0.25",
        borderRadius:"5px",
        backgroundColor:theme.palette.secondary.main
    }
}))