import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import {  Divider } from "@material-ui/core";
import { BigMenu, Navbar, ProductVideoAd, Testimonials, Featured, SignUpForm, Seo} from "../index";
import Logo from "./logo/logo";
import ServiceSection from "./serviceSection/serviceSection";
import ActionImage from "./actionImage/actionImage";

const Main = (props) => {
  const data = useStaticQuery(graphql`
      query {
      allDatoCmsProduct(filter: {featured: {eq: true}}) {
        edges {
            node {
                id
                name
                price
                description
                image {
                    url
                    sizes(maxWidth: 300, imgixParams: { fm: "webp" }) {
                        ...GatsbyDatoCmsSizes
                    }
                }
            }
        }
      }
      datoCmsConfig {
        logo {
          sizes(maxWidth: 300, imgixParams: { fm: "webp" }) {
            ...GatsbyDatoCmsSizes
          }
        }
        logoDark {
            sizes(maxWidth: 300, imgixParams: { fm: "webp" }) {
              ...GatsbyDatoCmsSizes
            }
        }
        logoAlt {
          sizes(maxWidth: 300, imgixParams: { fm: "webp" }) {
            ...GatsbyDatoCmsSizes
          }
      }
      }
      datoCmsMain {
        firstsection {
          text
          textPosition
          button1
          button2
          storeSearchWord
          image {
            sizes(maxWidth: 1920, imgixParams: { fm: "webp" }) {
              ...GatsbyDatoCmsSizes
            }
          }
        }
        threeSteps {
          stepOneImage {
            sizes(maxWidth: 900, imgixParams: { fm: "webp" }) {
              ...GatsbyDatoCmsSizes
            }
          }
          stepTwoImage {
            sizes(maxWidth: 900, imgixParams: { fm: "webp" }) {
              ...GatsbyDatoCmsSizes
            }
          }
          stepThreeImage {
            sizes(maxWidth: 900, imgixParams: { fm: "webp" }) {
              ...GatsbyDatoCmsSizes
            }
          }
          stepOneTitle
          stepTwoTitle
          stepThreeTitle
          stepOneDescription
          stepTwoDescription
          stepThreeDescription
        }
        headerBackground {
          sizes(maxWidth: 1920, imgixParams: { fm: "webp" }) {
            ...GatsbyDatoCmsSizes
          }
        }
        videoAd {
            videoId
            searchWords
        }
        testimonials{
            id
            author
            quote
        }
      }
    }
  `);
  return (
    <div {...props}>
      <Seo>
        <Navbar/>
        <Logo
          fluid={data.datoCmsConfig.logo.sizes}
          backgroundFluid={data.datoCmsMain.headerBackground.sizes}
          altFluid={data.datoCmsConfig.logoAlt.sizes}
        />
        <Divider />
        <BigMenu currentPage={"/"} />
        <SignUpForm/>
        <ActionImage data={data.datoCmsMain.firstsection} />
        <ServiceSection data={data.datoCmsMain.threeSteps[0]} />
        <ProductVideoAd data={data.datoCmsMain.videoAd[0]}/>
        <Testimonials data={data.datoCmsMain.testimonials} logo={data.datoCmsConfig.logoDark.sizes}/>
        <Featured products={data.allDatoCmsProduct.edges}/>
      </Seo>
    </div>
  );
};

export default Main;
