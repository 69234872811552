import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline'
import { Cart } from './src/components'

export const wrapRootElement = ({ element }) => (
    <>
        <CssBaseline /> 
        <Cart> 
            {element}
        </Cart> 
    </>
  );
