import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme)=>({
    root:{
        padding:"0px"
    },
    link:{
        textDecoration:"none"
    },
    product:{
        maxWidth:'100%',
        border:"5px solid " + theme.palette.secondary.main,
        padding:"25px 10px 5px 10px"
    },
    title:{
        minHeight:"40px"
    },
    divider:{
        height:"1px",
        width:"100%",
        marginBottom:"15px",
        marginLeft:"0px",
        backgroundColor:theme.palette.secondary.main,
        opacity:"0.25"
    },
    description:{
        minHeight:"100px", 
        fontFamily:'Lato,system-ui'
    },
    media:{
        height:'250px',
        width:'100%',
        backgroundColor:'white',
        position:"relative"
    },
    prices:{
        height:"50px",
        marginTop:"10px"
    },
    price:{
        marginTop:"10px",
        fontSize:"20px"
    },
    strike:{
        textDecoration:"line-through",
        fontSize:"13px"
    },
    cardAction:{
        display:'flex',
        justifyContent:'flex-end', 
        color:'white'
    },
    cardContent:{
        minHeight:'50px'
    },
    gatsbyImage:{
        height:'100%',
        "& img":{
            maxHeight:'100%',
            maxWidth:'110%',
            objectFit:'contain !important'
        }
    },
    cartAdd:{
        backgroundColor:'transparent',
        border:'none'
    },
    cartButtonText:{
        fontSize:"12px"
    },
    saleTag:{
        color:"green",
        position:"absolute",
        left:"0",
        bottom:"-20px",
        height:"50px",
        transformOrigin:"center right",
        transform:"rotate(-90deg)"
    },
    addCart:{
        width:"100%",
        padding:"15px 30px",
        borderRadius:"0px 0px 10px 10px",
        margin:"0px",
        backgroundColor:"#620015",
        color:"white",
        fontFamily:"system-ui",
        transition:'all .25s',
        border:"1px solid black",
        '&:hover':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        },
        '&:disabled p':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
        },
        '&:hover svg':{
            color:theme.palette.secondary.main
        },
        '&:hover p':{
            color:theme.palette.secondary.main
        },
        '& p':{
            fontFamily:"system-ui",
            fontWeight:"500"
        },
        [theme.breakpoints.down('sm')]:{
            padding:"15px 10px",
            '& p':{
                fontSize:"12px",
            }
        }
    }
}))