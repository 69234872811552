import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        padding:"50px 25px"
    },
    image:{
        // [theme.breakpoints.down('sm')]:{
        //     maxWidth:"200px",
        //     marginBottom:"25px"
        // }
    },
    divider:{
        width:"90%",
        marginBottom:"50px"
    },
    name:{
        textAlign:"center",
        [theme.breakpoints.down("sm")]:{
            fontSize:"35px"
        }
    },
    price:{
        marginBottom:"10px",
        [theme.breakpoints.down("sm")]:{
            marginLeft:"0px"
        }
    },
    attributes:{
        width:"70%",
        marginBottom:'50px'
    },
    from:{
        marginLeft:"15px"
    },
    description:{
        margin:"50px 0px"
    },
    button:{
        padding:"15px 30px",
        backgroundColor:theme.palette.secondary.main,
        color:theme.palette.textSecondary.main,
        transition:'all .25s',
        border:"2px solid white",
        '&:hover':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
            borderColor:theme.palette.textPrimary.main,
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)",
            textDecoration:"none"
        },
        [theme.breakpoints.up('md')]:{
            marginTop:"100px"
        },
        [theme.breakpoints.down('md')]:{
            marginTop:"15px",
            padding:"10px 15px"
        },
    }
}))