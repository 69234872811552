import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { ProductCard } from '../index'
import useStyles from './styles'

const Featured = ({products}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Typography className={classes.title} variant="h3" color={'secondary'}>
                Featured Products
            </Typography>
            <Grid container className={classes.products} justify="center" spacing={2}>
                {
                    products.map((product)=>{
                        return(<Grid item key={product.node.id} xs={12} sm={6} md={4} lg={3}>
                            <ProductCard product={product.node}/>
                        </Grid>)
                    })
                }
            </Grid>
        </div>
    )
}
export default Featured
