import React, {useState, useEffect} from "react";
import {
  Grid,
  Typography,
  Hidden
} from "@material-ui/core";
import Billing from './billing/billing'
import useStyles from "./styles";

const BillingInformation = ({ products, cartSubtotal, tax, discount, total, setStage, setCartProvince }) => {
  const classes = useStyles();
  const [province, setProvince] = useState("Ontario")
  const [shippingOption, setShippingOption] = useState(null)
  const [paymentOption, setPaymentOption] = useState(null)
  const required = (name) => (value) =>
    value ? undefined : `${name} is required`;
  const isEmail = (name) => (value) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(value).toLowerCase()) ?
        undefined : `Email is in an incorrect format`
    }
  const isPostal = (name) => (value) => {
    const re = /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i
    return re.test(String(value).toUpperCase()) ?
      undefined : `Postal code is in an incorrect format`
  }
  const minLength = min => value => (value.length < min ? `Should be greater than ${min} characters` : undefined)
  const composeValidators = (...validators) => value =>
      validators.reduce((error, validator) => error || validator(value), undefined)
  const formFields = [
    {
        id:"shippingOptions",
        label:"Shipping Options",
        size:12,
        onChange:(e)=>{setShippingOption(e.target.value)},
        radioAriaLabel:"shipping-options",
        radioName:"shippingOptions",
        radioButtons:[
            {
              id:"pickup",
              value:"pickup",
              label:"Pick Up"        
          },
          {
              id:"delivery",
              value:"delivery",
              label:"Local Delivery (within 5km radius)"
          },
          // {
          //     id:"ship",
          //     value:"ship",
          //     label:"Ship"
          // }
        ]
      },
      {
        id:"paymentOptions",
        label:"Payment Options",
        size:12,
        onChange:(e)=>{setPaymentOption(e.target.value)},
        radioAriaLabel:"payment-options",
        radioName:"paymentOptions",
        hidden:["hideOnNoShipping", "ship"],
        radioButtons:[
            {
              id:"instore",
              value:"instore",
              label:{default:"Pay In Person", delivery:"Pay on Delivery", pickup:"Pay In Store"}[shippingOption ? shippingOption : "default"]        
          },
          {
              id:"online",
              value:"online",
              label:"Pay Online"
          },
        ]
      },
    {
      id: "name",
      label: "Name",
      validate: required("Name"),
      size: 12,
      hidden:["hideOnNoPayment"]
    },
    {
      id: "email",
      label: "Email",
      validate: composeValidators(required("Email"),isEmail("Email address")),
      size: 12,
      hidden:["hideOnNoPayment"]
    },
    {
      id: "address",
      label: "Street Address",
      validate: required("Address"),
      size: 10,
      hidden:["pickup","hideOnNoPayment"]
    },
    {
      id: "apt",
      label: "Apt/Suite",
      size: 2,
      hidden:["pickup","hideOnNoPayment"]
    },
    {
      id: "city",
      label: "City",
      validate: required("City"),
      size: 12,
      hidden:["instore", "hideOnNoPayment"]
    },
    // {
    //   id: "country",
    //   label: "Country",
    //   size: 12,
    // },
    {
      id: "province",
      label: "Province",
      size: 8,
      onChange:(e)=>{setProvince(e.target.value)},
      hidden:["instore", "hideOnNoPayment"],
      select:[
          "Alberta",
          "British Columbia",
          "Manitoba",
          "New Brunswick",
          "Newfoundland",
          "Northwest Territories",
          "Nova Scotia",
          "Nunavat",
          "Ontario",
          "Prince Edward Island",
          "Quebec",
          "Saskatchewan",
          "Yukon"
      ]
    },
    {
      id: "postal",
      label: "Postal",
      validate: composeValidators(required("Postal Code"), isPostal("Postal Code")),
      size: 4,
      hidden:["instore", "hideOnNoPayment"]
    },
    
  ];
  useEffect(()=>{
    ["delivery", "pickup"].includes(shippingOption) && setProvince("Ontario")
    shippingOption === "ship" && setPaymentOption("online")
  },[shippingOption, setProvince, setPaymentOption])

  useEffect(()=>{
    setCartProvince(province)
  },[province, setCartProvince])
  return (
    <Grid container justify="center" spacing={4}>
      <Billing formFields={formFields} shippingOption={shippingOption} paymentOption={paymentOption} setStage={setStage} province={province}/>
      <Hidden smDown>
      <Grid item xs={5}>
        <Grid container className={classes.summary}>
            <Grid item xs={12}>
                <Typography variant={"h6"}>Order Summary</Typography>
            </Grid>
            <Grid item xs={12} className={classes.summaryItems}>
                {products.map((product) => (
                    <Grid
                        container
                        className={classes.summaryItem}
                        key={product.id}
                    >
                        <Grid key={product.id} item xs={8}>
                            <Typography
                                className={classes.summaryText}
                                variant={"body2"}
                            >
                                {product.name}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography
                                className={classes.summaryText}
                                variant={"body2"}
                            >
                                X{product.quantity}
                            </Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography
                                className={classes.summaryText}
                                variant={"body2"}
                            >
                                CA${product.price}
                            </Typography>
                        </Grid>
                    </Grid>
                  ))}
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={4}>
                <Typography className={classes.summaryText} variant={"body2"}>
                    Subtotal
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.summaryText} variant={"body2"}>
                    { cartSubtotal }
                </Typography>
            </Grid>
            {
              discount ? 
              <>
                <Grid item xs={6}></Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.summaryText} variant={"body2"}>
                            Discount
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.summaryText} variant={"body2"}>
                            -{discount}
                        </Typography>
                    </Grid>
              </>
              :
              null
            }
            { 
                province && 
                <>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={4}>
                        <Typography className={classes.summaryText} variant={"body2"}>
                            Taxes
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography className={classes.summaryText} variant={"body2"}>
                            { tax ? tax.total : "0.00"}
                        </Typography>
                    </Grid>
                </>
            }
            <Grid item xs={6}></Grid>
            <Grid item xs={4}>
                <Typography className={classes.summaryText} variant={"body2"}>
                    TOTAL
                </Typography>
            </Grid>
            <Grid item xs={2}>
                <Typography className={classes.summaryText} variant={"body2"}>
                    ${ province 
                    ?
                      total
                      // {total}
                      // (parseFloat(cartSubtotal) - 
                      //   (discount ? parseFloat(discount) : 0) 
                      //   + parseFloat(taxCalculator(cartSubtotal - discount, province.toLowerCase()).total)
                      // ).toFixed(2)
                    :
                       cartSubtotal - (discount ? discount : 0)}
                </Typography>
            </Grid>
        </Grid>
        </Grid>
        </Hidden>
    </Grid>
  );
};

export default BillingInformation;
