import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    billing:{
        backgroundColor:"white",
        marginTop:"50px",
        marginLeft:"25px"
    },
    submitButton:{
        width:"200px",
        backgroundColor:"#620015",
        border:"1px solid #620015",
        color:"white",
        marginTop:"25px",
        '&:hover':{
            backgroundColor:"white",
            color:"#620015"
        }
    }
}))