import React from 'react'
import { Typography } from '@material-ui/core'
import useStyles from './styles'

const ConfirmationTitle = ({text}) => {
    const classes = useStyles()
    return (
        <Typography className={classes.root} variant={'h5'} gutterBottom>
            {text}
        </Typography>
    )
}

export default ConfirmationTitle
