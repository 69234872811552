import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        width:"100%",
        height:"590px",
        backgroundColor:"black",
        [theme.breakpoints.down('md')]:{
            height:"485px"
        },
        [theme.breakpoints.down('sm')]:{
            height:"327px"
        },
        [theme.breakpoints.down('xs')]:{
            height:"225px"
        }
    },
    video:{
        width:"100%",
        height:"100%"
    }
}))