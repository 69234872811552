import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './styles'

const OrderSummary = ({subtotal,discount, taxes}) => {
    const classes = useStyles()
    const total = (parseFloat(subtotal) - (discount ? parseFloat(discount.amount) : 0) + parseFloat(taxes.total)).toFixed(2)
    return (
        <div className={classes.root}>
            <Typography variant={'h5'} gutterBottom>
                Order Summary
            </Typography>
            <Grid container>
                <Grid item xs={2}>
                    <Typography variant={'body2'}>
                        Subtotal
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.total} variant={'body2'}>
                        ${subtotal}
                    </Typography>
                </Grid>
                <Grid item xs={7}/>
                {
                    discount ? 
                    <>
                        <Grid item xs={2}>
                            <Typography variant={'body2'}>
                                Coupon
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Typography className={classes.total} variant={'body2'}>
                                -{discount.amount}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}/>
                    </>
                    :
                    null
                }
                {
                    taxes.itemized.map((tax)=>(
                        <React.Fragment key={tax.name}>
                            <Grid item xs={3}>
                                <Typography variant={'body2'}>
                                    {tax.name.toUpperCase()}
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography className={classes.total} variant={'body2'}>
                                    {tax.amount}
                                </Typography>
                            </Grid>
                            <Grid item xs={7}/>
                        </React.Fragment>
                    ))
                }
                <Grid item xs={2}>
                    <Typography variant={'body2'}>
                        <strong>Total</strong>
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography className={classes.total} variant={'body2'}>
                        <strong>${total}</strong>
                    </Typography>
                </Grid>
                <Grid item xs={7}/>
            </Grid>
        </div>
    )
}

export default OrderSummary
