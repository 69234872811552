export const useValidate = (data) => ({
    isRequired:(data)=>(data.value.length > 0 ? null : `This field is required`),
    emailFormat:(data)=>{
        const email = data.value
        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(String(email).toLowerCase()) ? null : 'Email address is in incorrect format'
    },
    nineteenOrOver:(data)=>{
        const today = new Date()
        const inputValue = new Date(data.value)
        const yearDiff = today.getFullYear() - 19 - inputValue.getFullYear()
        const monthDiff = today.getMonth() - inputValue.getMonth()
        const dayDiff = today.getDate() - inputValue.getDate()
        return (
            yearDiff > 0 
            || (yearDiff === 0 && monthDiff > 0)
            || (yearDiff === 0 && monthDiff === 0 && dayDiff > 0)
            ?
            null
            :
            `You must be 19 or older to book an appointment`
        )

    },
    bookedWithinHours:(data)=>{
        const inputValue = new Date(data.value)
        const inputHour = inputValue.getHours()
        const inputMinutes = inputValue.getMinutes()
        const inputDayOfWeek = ['sunday', 'monday','tuesday','wednesday','thursday','friday','saturday'][inputValue.getDay()]
        if(!data.timeArr[inputDayOfWeek])return `Please select a different day`
        const minTime = data.timeArr[inputDayOfWeek].min
        const maxTime = data.timeArr[inputDayOfWeek].max
        console.log(inputDayOfWeek, minTime, maxTime)
        const minTimeSplit = minTime.split(':')
        const maxTimeSplit = maxTime.split(':')
        const displayMaxTime = maxTimeSplit[0] > 12 ? (maxTimeSplit[0] - 12) + ":" + maxTimeSplit[1] : maxTimeSplit[0] + ":" + maxTimeSplit[1]
        const withinRange = 
            inputHour >= parseInt(minTimeSplit[0]) && inputHour <= parseInt(maxTimeSplit[0])
            ?
                inputHour === parseInt(minTimeSplit[0])
                ?
                    inputMinutes >= parseInt(minTimeSplit[1])
                    ?
                        null
                    :
                        `Time must be between ${data.minTime} and ${displayMaxTime} on this date`
                :
                    inputMinutes <= parseInt(maxTimeSplit[1])
                        ?
                            null
                        :
                        `Time must be between ${data.minTime} and ${displayMaxTime} on this date`
            :
            `Time must be between ${data.minTime} and ${displayMaxTime} on this date`

        return withinRange
    },
    isWeekDay:(data)=>{
        const inputValue = new Date(data.value)
        return inputValue.isWeekDay ? null : ``
    },
    phoneFormat:(data)=>{
        const phone = data.value
        const reg = /^\+?1?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/
        console.log("REG TEST", reg.test(String(phone).toLowerCase()))
        return reg.test(String(phone).toLowerCase()) ? null : `Please enter phone number with area code`
    }
})

export default useValidate