import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        minHeight:"200px",
        backgroundColor:theme.palette.primary.main,
        borderTop:"1px solid " + theme.palette.secondary.main,
        padding:"15px"
    },
    section:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center",
        alignItems:"center",
        marginTop:"25px"
    },
    logoContainer:{
        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    },
    logo:{
        width:"100px",
    },
    text:{
        color:"white"
    },
    borderBottom:{
        width:"50%",
        textAlign:"center",
        borderBottom:"1px solid " + theme.palette.secondary.main
    },
    footerContactButton:{
        backgroundColor:theme.palette.secondary.main,
        padding:"10px 15px",
        margin:"2.5px 0px",
        '& a':{
            color:theme.palette.secondary.contrastText,
            textDecoration:'none'
        },
        '&:hover':{
            backgroundColor:theme.palette.secondary.contrastText,
            '& a':{
                color:theme.palette.secondary.main
            }
        }
    },
    customLabel:{
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
        marginTop:"25px",
        borderTop:"2px solid " + theme.palette.secondary.main
    },
    customLabelButton:{
        width:"250px",
        margin:"10px 0px"
    },
    copyright:{
        marginTop:"50px",
        '& p':{
            color:"white"
        }
    }
}))