import React, { useState } from "react";
import { Grid, Button, Fade } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import { Link } from "gatsby";
import useStyles from "./styles";

const BigMenu = ({ onClick, currentPage }) => {
  const classes = useStyles();
  const [subMenu, setSubMenu] = useState(null);
  const menuItems = [
    {
      display: "Wine",
      subMenu: [
        {
          display: "Craft Some Wine",
          to: "/make-wine",
        },
        {
          display: "Wine Menu",
          to: "/wine-list",
        },
        {
          display: "Wine Ingredients",
          to:"/store",
          state:{
            searchFor:"Wine Ingredients"
          }
        },
        {
          display: "Wine Kits",
          to:"/store",
          state:{
            searchFor:"Wine Kits"
          }
      },
        {
          display: "The Winemaking Process",
          to: "/wine-making",
        },
        {
          display: "Wine Troubleshooting",
          to:"/wine-troubleshooting"
        },
      ],
    },
    {
      display: "Beer",
      subMenu:[
        {
            display:"Craft Some Beer",
            to:"/craft-beer",
            cta:true
        },
        {
            display:"Beer Menu",
            to:"/beer-list"
        },
        {
          display:"Beer Ingredients",
          to:"/store",
          state:{
            searchFor:"Beer Ingredients"
          }
        },
        {
          display:"Beer Kits",
          to:"/store",
          state:{
            searchFor:"Beer Kits"
          }
        },
        {
          display:"How to Craft Beer",
          to:"/beer-crafting"
        },
        {
            display:"Yeast",
            to:"/store",
            state:{
                searchFor:"Beer Yeast"
            }
        },
        {
          display:"Grain",
          to:"/store",
          state:{
            searchFor:"Grain"
          }
        },
        {
          display:"Hops",
          to:"/store",
          state:{
              searchFor:"Hops"
          }
        }                
    ]
    },
    {
      display:"Other Brews",
      subMenu:[
          {
              display:"Cider",
              to:"/store",
              state:{
                  searchFor:"Cider"
              }
          }
      ]
    },
    {
      display: "equipment",
      subMenu: [
        {
          display:"Bottling",
          to:"/store",
          state:{
            searchFor:"Bottling"
          }
        },
        {
          display:"Distilling",
          to:"/store",
          state:{
            searchFor:"Distilling"
          }
        },
        {
          display: "Fermenting",
          to: "/store",
          state:{
              searchFor:"Fermenting"
          }
        },
        {
          display:"Filtering",
          to:"/store",
          state:{
              searchFor:"Filtering"
          }
        },
        {
          display:"Kegging",
          to:"/store",
          state:{
            searchFor:"Kegging"
          }
        },
        {
          display:"Sanitizing",
          to:"/store",
          state:{
              searchFor:"Sanitizing"
          }
        },
        {
          display:"Siphoning",
          to:"/store",
          state:{
            searchFor:"Siphoning"
          }
        },
        {
          display:"Testing",
          to:"/store",
          state:{
            searchFor:"Testing"
          }
        },
      ],
    },
    {
      display: "University",
      subMenu: [
        {
          display: "The Perfect Pair Cooking Series",
          to: "/university/the-perfect-pair-cooking-series",
        },
      ],
    },
  ];
  const handleEquipmentClicked = (e, value) => {
      e.preventDefault()
      onClick(value.state.searchFor)
  }
  const GoTo = ({ value, isRow }) => (
    <Grid
      className={classes.linkContainer}
      key={value.display}
      item
      xs={isRow ? 4 : 12}
      md={isRow ? 2 : 12}
    >
      <Link className={classes.link} onClick={currentPage === value.to && value.to.includes("store") && value.state ? (e)=>{handleEquipmentClicked(e, value)} : null} to={value.to} state={value.state}>
        <Button
          className={
            value.cta ? `${classes.button} ${classes.cta}` : classes.button
          }
        >
          {value.display.toUpperCase()}
        </Button>
      </Link>
    </Grid>
  );
  return (
    <Fade in timeout={1000}>
    <div className={classes.root}>
      <Grid className={classes.menuRoot} container justify="center" spacing={1}>
      <Grid item md={1}/> 
        {menuItems.map((value) =>
          <React.Fragment key={value.display}>
          {value.to ? (
            <GoTo value={value} isRow={true} />
          ) : (
            <Grid item xs={4} md={2}>
              <div className={classes.link}>
                <Button
                  onClick={() => {
                    setSubMenu(
                      subMenu === value.display ? null : value.display
                    );
                  }}
                  className={
                    value.display === subMenu
                      ? `${classes.button} ${classes.active}`
                      : classes.button
                  }
                >
                  {value.display.toUpperCase()}
                  {value.subMenu ? <ExpandMore /> : null}
                  {value.display === subMenu ? (
                    <Grid
                      container
                      className={classes.subMenu}
                      direction="column"
                    >
                      <Fade in timeout={1000}>
                        <div className={classes.wine}>
                          {value.subMenu.map((item) => (
                            <GoTo key={item.display} value={item} isRow={false} />
                          ))}
                          <Grid
                            className={classes.linkContainer}
                            item
                            xs={12}
                            />
                        </div>
                      </Fade>
                    </Grid>
                  ) : null}
                </Button>
              </div>
            </Grid>
          )}
          </React.Fragment>
        )}<Grid item md={1}/>
      </Grid>
    </div>
    </Fade>
  );
};

export default BigMenu;
