import React, {useCallback, useEffect, useState} from 'react'
import {Grid, Typography, Hidden, Button} from '@material-ui/core'
import useFirebase from "./../../../hooks/useFirebase"
import useStyles from './styles'

const AdminPage = () => {
    const firebase = useFirebase()
    const classes = useStyles()
    const [appointments, setAppointments] = useState([])
    const setAppointmentData = useCallback(() => {
        firebase &&
          firebase
            .database()
            .ref("appointments/")
            .once("value")
            .then((snap) => {
              if (!snap.val()) return;
              const appts = snap.val();
               const values = Object.values(appts)
               sortByDate(values)
            })
            .catch((error)=>{
                console.log("ERROR", error)
            });
      },[firebase]);
    const sortByName = () => {
        const sorted = JSON.parse(JSON.stringify(appointments)).sort((a,b)=>(
            a.name < b.name
            ?
            -1
            :
            a.name > b.name
            ?
            1
            :
            0
        ))
        setAppointments(sorted)
    }
    const convertDate = (date) => {
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const apptDateArr = date.split(" ")
        const month = monthNames.findIndex(month => month === apptDateArr[0])
        const day = parseInt(apptDateArr[1])
        const year = "2021"
        const timeArr = apptDateArr[2].split(":")
        const hour = parseInt(timeArr[0]) < 7 ? parseInt(timeArr[0]) + 12 : timeArr[0]
        const minute = parseInt(timeArr[1])
        return new Date(year, month, day, hour, minute)
    }
    const sortByDate = (overrideStateAppointment) => {
        const dataSource = overrideStateAppointment ? overrideStateAppointment : appointments
        const sorted = JSON.parse(JSON.stringify(dataSource)).sort((a,b)=>(
            convertDate(a.date) > convertDate(b.date)
            ?
            1
            :
            convertDate(a.date) < convertDate(b.date)
            ?
            -1
            :
            0
        ))
        setAppointments(sorted)
    }
    useEffect(()=>{
        if(firebase)setAppointmentData()
    },[firebase])
    return (
        <Grid className={classes.root} container>
            <Grid item xs={12}>
                <Typography className={classes.title} variant="h5" align="center">
                    Appointments
                </Typography>
            </Grid>
            <Hidden smDown>
            <Grid item xs={12} className={classes.headRow}>
                <Grid container>
                <Grid xs={1}/>
                <Grid item xs={6} md={3}>
                    <Typography variant="h5">
                        Date
                    </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Typography variant="h5">
                        Name
                    </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                    <Typography variant="h5">
                        Contact
                    </Typography>
                </Grid>
                <Grid item xs={6} md={2}>
                    <Typography variant="h5">
                        Birthdate
                    </Typography>
                </Grid>
                <Grid item xs={1}/>
                </Grid>
            </Grid>
            </Hidden>
            <Grid item xs={12}>
                {
                    JSON.parse(JSON.stringify(appointments))
                    .filter((appt)=>(
                        convertDate(appt.date).getTime() 
                        > 
                        (new Date()).getTime()
                    ))
                    .map((appointment, key)=>{ console.log("KEY", key)
                        return(
                            <Grid 
                             key={appointment.name + appointment.date} 
                             container 
                             className=
                                {
                                    new Date(appointment.creationTime).getTime() 
                                    > 
                                    (new Date()).getTime() - 604800000
                                    ? 
                                    key === 0
                                    ?
                                    `${classes.row} ${classes.new} ${classes.top}` 
                                    : 
                                    `${classes.row} ${classes.new}`
                                    :
                                    key===1
                                    ?
                                    `${classes.row} ${classes.top}`
                                    :
                                    classes.row
                                }

                             >
                                <Grid xs={1}/>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body2">
                                        {appointment.date}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Typography variant="body2">
                                        {appointment.name}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography variant="body2">
                                        {appointment.phone}
                                    </Typography>
                                    <Typography variant="body2">
                                        {appointment.email}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <Typography variant="body2">
                                        {appointment["birth-date"]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1}/>
                            </Grid>
                            
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}

export default AdminPage
