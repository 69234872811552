// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-appointments-js": () => import("./../../../src/pages/appointments.js" /* webpackChunkName: "component---src-pages-appointments-js" */),
  "component---src-pages-beer-crafting-js": () => import("./../../../src/pages/beer-crafting.js" /* webpackChunkName: "component---src-pages-beer-crafting-js" */),
  "component---src-pages-beer-list-js": () => import("./../../../src/pages/beer-list.js" /* webpackChunkName: "component---src-pages-beer-list-js" */),
  "component---src-pages-craft-beer-js": () => import("./../../../src/pages/craft-beer.js" /* webpackChunkName: "component---src-pages-craft-beer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-make-wine-js": () => import("./../../../src/pages/make-wine.js" /* webpackChunkName: "component---src-pages-make-wine-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-pages-store-product-dato-cms-product-name-js": () => import("./../../../src/pages/store/product/{datoCmsProduct.name}.js" /* webpackChunkName: "component---src-pages-store-product-dato-cms-product-name-js" */),
  "component---src-pages-university-the-perfect-pair-cooking-series-js": () => import("./../../../src/pages/university/the-perfect-pair-cooking-series.js" /* webpackChunkName: "component---src-pages-university-the-perfect-pair-cooking-series-js" */),
  "component---src-pages-wine-list-js": () => import("./../../../src/pages/wine-list.js" /* webpackChunkName: "component---src-pages-wine-list-js" */),
  "component---src-pages-wine-making-js": () => import("./../../../src/pages/wine-making.js" /* webpackChunkName: "component---src-pages-wine-making-js" */),
  "component---src-pages-wine-troubleshooting-js": () => import("./../../../src/pages/wine-troubleshooting.js" /* webpackChunkName: "component---src-pages-wine-troubleshooting-js" */)
}

