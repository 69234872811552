import React, {useContext} from 'react'
import { Grid, Typography, Divider, IconButton } from '@material-ui/core'
import { AddShoppingCart, Event, ShoppingCart } from '@material-ui/icons'
import { Link } from 'gatsby'
import { StructuredText } from "react-datocms";
import { CartContext } from '../cart/cart'
import Img from 'gatsby-image'
import useStyles from './styles'

const Product = ({product}) => {
    const classes = useStyles()
    const { addToCart, setCartOpen } = useContext(CartContext)
    const { name, description, price, salePrice, image, longDescription, listSeries } = product
    return (
        <Grid container direction={"column"} alignItems={"center"} justify={"center"} className={classes.root}>
            <Grid className={classes.title} item>
                <Typography variant="h4">
                    { name }
                </Typography>
            </Grid>
            <Divider className={classes.divider}/>
            <Grid item>
                <Grid container justify={"center"}>
                    <Grid className={classes.image} item xs={12} md={4}>
                        <Img fluid={image.sizes} loading="eager"/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Grid container className={classes.descriptionContainer} direction="column" alignItems="center" justify="center">
                            <Grid className={classes.price} item xs={12}>
                                <Typography id="price" variant="h5">
                                    ${salePrice ? salePrice : price}
                                </Typography>
                            </Grid>
                            <Grid className={classes.description} item xs={10} md={6}>
                                <Typography variant="body2">
                                    {description}
                                </Typography>
                            </Grid>
                            <Grid className={classes.buttonContainer} item xs={6}>
                            <IconButton disabled={product.soldOut} onClick={product.inCart ? (e)=>{e.preventDefault(); setCartOpen(true)} : (e)=>{e.preventDefault(); product.inCart=true; addToCart(product)}} className={classes.iconButton}>
                                {
                                    product.inCart ? <ShoppingCart/> : !product.soldOut ? <AddShoppingCart/> : null
                                }
                                <Typography className={classes.cartButtonText} variant={"body2"} color={"textSecondary"}>
                                    {!product.soldOut ? product.inCart ? "IN CART" : "ADD TO CART":"OUT OF STOCK"}
                                </Typography>
                            </IconButton>
                            </Grid>
                            {
                                listSeries &&
                                <Grid className={classes.buttonContainer} item xs={6}>
                                    <Link style={{ textDecoration: 'none' }} classes={classes.link} to={'/make-wine'}
                                        state={{item:product.name}}
                                    >
                                        <IconButton className={classes.iconButton}>
                                            <Event className={classes.icon}/>
                                            <Typography variant={'body2'}>
                                                BOOK APPOINTMENT
                                            </Typography>
                                        </IconButton>
                                    </Link>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Divider className={classes.divider}/>
            {
                longDescription.value &&
                (
                    <Grid className={classes.longDescription} item xs={12} md={8}>
                        <StructuredText data={longDescription}/>
                    </Grid>
                )
            }
        </Grid>
    )
}

export default Product
