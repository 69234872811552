import React, { useState, useContext } from 'react'
import { Grid, Typography, Paper, Divider, IconButton, Accordion, AccordionSummary, AccordionDetails, Fade } from '@material-ui/core'
import { CartContext } from '../cart/cart'
import { Event, ShoppingCart, AddShoppingCart, ExpandMore, ArrowBack } from '@material-ui/icons'
import slugify from '@sindresorhus/slugify'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import { Navbar, BigMenu } from '..'
import useStyles from './styles'

const MenuList = ({dataSource, onItemClicked, closeMenu}) => {
    const data = useStaticQuery(graphql`
        query {
            allDatoCmsWineList {
                edges {
                    node {
                        description
                        name
                    }
                }
            }
            allDatoCmsBeerList {
                edges{
                    node{
                        description
                        name
                    }
                }
            }
            allDatoCmsProduct(filter: {listSeries: {ne: null}}, sort: {fields: listSeries}) {
                edges {
                node {
                    id
                    name
                    price
                    salePrice
                    description
                    soldOut
                    listSeries
                    word{
                        word
                    }
                    image {
                        url
                        sizes(maxWidth: 300, imgixParams: { fm: "webp" }) {
                            ...GatsbyDatoCmsSizes
                        }
                    }
                }
                }
            }
        }
    `)
    const [expandedList, setExpandedList] = useState({})
    const listSeriesSource = dataSource === "wine" ? data.allDatoCmsWineList : data.allDatoCmsBeerList
    const seriesList = listSeriesSource.edges.map((edge)=>(
        {name:edge.node.name, description:edge.node.description}
    ))
    const listData = {}
    data.allDatoCmsProduct.edges.forEach((edge)=>{
        const seriesName = edge.node.listSeries
        const wordList = edge.node.word.map((word)=>(word.word.toLowerCase()))
        if(seriesName==="" || !wordList.includes(dataSource))return
        const series = seriesList.filter((series)=>(
                series.name === seriesName
            ))[0]
        if(series){
            if(!listData[seriesName]){
                listData[seriesName] = {
                    description:series ? series.description : "",
                menuItems:[]
            }}
            listData[seriesName].menuItems.push(edge.node)
        }
    })

    const classes = useStyles()
    const { addToCart, setCartOpen, } = useContext(CartContext)
    const CardAction = ({children, item}) =>{
        if(!onItemClicked)
        {
            return (<>
                <a className={classes.link} href={"/store/product/" + slugify(item.name)}>
                {children}
                </a>
                <Grid container justify="center">
                    <Grid item xs={6}>
                        <IconButton disabled={item.soldOut} onClick={item.inCart ? (e)=>{e.preventDefault(); setCartOpen(true)} : (e)=>{e.preventDefault(); item.inCart=true; addToCart(item)}} className={classes.iconButton}>
                            {
                                item.inCart ? <ShoppingCart className={classes.icon}/> : !item.soldOut ? <AddShoppingCart className={classes.icon}/> : null
                            }
                            <Typography className={classes.cartButtonText} variant={"body2"} color={"textSecondary"}>
                                {!item.soldOut ? item.inCart ? "IN CART" : "ADD TO CART":"OUT OF STOCK"}
                            </Typography>
                        </IconButton>

                        {/* <IconButton disabled={product.soldOut} onClick={product.inCart ? (e)=>{e.preventDefault(); setCartOpen(true)} : (e)=>{e.preventDefault(); product.inCart=true; addToCart(product)}} className={classes.iconButton}>
                                {
                                    product.inCart ? <ShoppingCart/> : !product.soldOut ? <AddShoppingCart/> : null
                                }
                                <Typography className={classes.cartButtonText} variant={"body2"} color={"textSecondary"}>
                                    {!product.soldOut ? product.inCart ? "IN CART" : "ADD TO CART":"OUT OF STOCK"}
                                </Typography>
                            </IconButton> */}

                    </Grid>
                    <Grid item xs={6}>
                        <Link style={{ textDecoration: 'none' }} classes={classes.link} to={'/make-wine'}
                            state={{item:item.name}}
                        >
                        <IconButton className={classes.iconButton}>
                            <Event className={classes.icon}/>
                            <Typography variant={'body2'}>
                                BOOK APPOINTMENT
                            </Typography>
                        </IconButton>
                        </Link>
                    </Grid>
                </Grid>
                </>)
        }
        if(onItemClicked){return <button className={classes.button} onClick={()=>{onItemClicked(item)}} onKeyDown={()=>{onItemClicked(item)}}>{children}</button>}
    }
    const Card = ({image, item}) =>(
        <Grid className={classes.itemCardContainer} item xs={12} md={6}>
                <CardAction item={item}>
                    <Grid container className={onItemClicked ? `${classes.itemCard} ${classes.borderBottom}` : classes.itemCard}>
                        <Grid item xs={3}> 
                            <Img fluid={image.sizes}/>
                        </Grid>
                        <Grid className={classes.info} item xs={9}>
                            <Typography color='secondary' variant={'h6'}>
                                {item.name}
                            </Typography>
                            <Divider className={classes.divider}/>
                            <Typography className={classes.description} variant={'body2'}>
                                {item.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardAction>
            </Grid>
    )
    return (
        <>
            <Navbar/>
            {!onItemClicked && <BigMenu/>}
            <Fade in timeout={1000}>
                <div className={classes.root}>
                    {
                        onItemClicked && <IconButton onClick={()=>{closeMenu(null)}}>
                                            <ArrowBack/>
                                        </IconButton>
                    }
                    {
                        (Object.keys(listData).map((key)=>(
                            <Paper key={key} className={classes.paper}>
                                <Accordion className={classes.accordion} square expanded={expandedList[key]} onClick={()=>{setExpandedList(Object.assign({}, expandedList, {key:!expandedList[key]}))}}>
                                    <AccordionSummary>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <Typography className={classes.title} variant={'h3'}>   
                                                    {key} Series
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography className={classes.seriesDescription} variant={'body2'}>
                                                    { listData[key].description}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails className={classes.menuListItems}>
                                        <Grid container>           
                                        {
                                            listData[key].menuItems.map((item)=>{
                                                return <Card key={item.id} image={item.image} item={item}/>               
                                            })
                                        }
                                        </Grid>
                                    </AccordionDetails>
                                    <ExpandMore/>
                                </Accordion>
                            </Paper>
                                )
                            )
                        )
                    }       
                </div>  
            </Fade>
        </>
    )
}


export default MenuList
