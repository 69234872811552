import React, {useEffect, useState} from 'react'
import { Modal, Fade, Grid, Typography, IconButton, Button, FormControl, Input, InputLabel} from '@material-ui/core'
import { Delete, Add, Remove, Close } from '@material-ui/icons'
import Img from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { Checkout } from '../../../components'
import BillingInformation from './billingInformation/billingInformation'
import CloseCartButton from './closeCartButton/closeCartButton'
import Confirmation from './confirmation/confirmation'
import useStyles from './styles'

const CartModal = ({open, products, setCartOpen, changeQuantity, removeProduct, cartSubtotal, setCartProvince, taxAmount, totalTax, totalAmount, lastOrder, setCartState, stage, setStage, discount, setDiscount, discountError, setDiscountError}) => {
    const data = useStaticQuery(graphql`
    query {
        allDatoCmsDiscountCode {
            edges {
                node {
                    code
                    amount
                    minimumPurchase
                }
            }
        }
    }
  `)
    const codes = data.allDatoCmsDiscountCode.edges.map((edge)=>(edge.node))  
    const classes = useStyles()
    const [showAddCouponButton, setShowAddCouponButton] = useState(false)
    // const [discountError, setDiscountError] = useState(null)
    const handleCouponInput = (e) => {
        if(e.currentTarget.value.length > 0){setDiscountError(null); setShowAddCouponButton(true)}
        else{setShowAddCouponButton(false)}
    }
    const subtotal = cartSubtotal()
    const validateDiscount = discount && subtotal > discount.minimumPurchase
    const validateCouponCode = (e) => {
        const userCode = e.currentTarget.parentNode.querySelector('input').value
        const validCode = codes.filter((code)=>(
            code.code.toLowerCase() === userCode.toLowerCase()
        ))
        if(validCode.length > 0){
            setDiscount(validCode[0])
        } else {
            setDiscountError("That is not a valid coupon code")
        }
    }
    const discountErr = discount && !validateDiscount ? `You must spend $${(discount.minimumPurchase - subtotal).toFixed(2)} more to activate this discount` : discountError
    const tax = totalTax()
    const totlAmount = (parseFloat(cartSubtotal()) + (tax ? parseFloat(tax.total) : 0) - (discount && !discountErr ? discount.amount : 0)).toFixed(2)
    const discountAmount = validateDiscount
        ? discount.amount.includes('%') ? (subtotal * (parseFloat(discount.amount) / 100)).toFixed(2) : discount.amount : 0

    useEffect(()=>{
        if(stage==="confirmation"){
            products.forEach((product)=>{
                product.inCart = false
            })
            setCartState([])
        }
    }, [stage, setCartState])
    return (
        <Modal
            className={classes.root}
            open={(open && stage==="confirmation") || (open && products.length > 0)} 
            hideBackdrop   
        >
            <>
            <CloseCartButton setCartOpen={setCartOpen}/>
            { stage === "viewcart" &&
            <Fade in={open}>
                <Grid container className={classes.cartContainer} justify="center">
                    <Grid item className={classes.itemList} xs={12} md={12}>
                        {
                            products.map((product)=>(
                                <Grid key={product.id} className={classes.productItem} container>
                                    <Grid className={classes.imageContainer} item xs={1} md={3}>
                                        <Img fluid={product.image.sizes}/>
                                    </Grid>
                                    <Grid className={classes.productDescription} item xs={12} md={9}>
                                        <Grid container>
                                            <Grid item xs={11}>
                                                <Typography variant={"h6"}>
                                                    {product.name}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={1}>
                                                <IconButton className={classes.button} onClick={()=>removeProduct(product)}>
                                                    <Delete className={classes.deleteButton}/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                        <Grid container>
                                            <Grid item xs={2} sm={6} md={8}></Grid>
                                            <Grid item xs={8} sm={5} md={3}>
                                                <div className={classes.incrementor}>
                                                    <div>
                                                        <IconButton className={classes.incrementButton} onClick={()=>{changeQuantity(product, 1)}}>
                                                            <Add/>
                                                        </IconButton>
                                                    </div>
                                                    <div>
                                                        {product.quantity}
                                                    </div>
                                                    <div>
                                                        <IconButton className={classes.incrementButton} onClick={()=>{changeQuantity(product, -1)}}>
                                                            <Remove/>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                               
                                            </Grid>
                                            <Grid item xs={2} sm={1} className={classes.price}>
                                                ${parseFloat(product.price * product.quantity, 2).toFixed(2)}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={3} md={2}>
                        <Typography variant={'body2'}>SUBTOTAL:</Typography>
                    </Grid>
                    <Grid item xs={8} md={9}>
                        <Typography variant={'body2'}>${ cartSubtotal() }</Typography>         
                    </Grid>
                    <Grid item xs={1}></Grid>
            {       discount ?
                        <>
                            <Grid item xs={3} md={2}>
                                <Typography variant={'body2'}>{discount.code}</Typography>
                            </Grid>
                            <Grid item xs={3} md={1}>
                                <Typography variant={'body2'}>{discountAmount ? '- $' + discountAmount : ""}</Typography>         
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton className={classes.closeButton} onClick={()=>{setDiscount(null); setShowAddCouponButton(false)}}>
                                    <Close/>
                                </IconButton>
                            </Grid>
                            <Grid item xs={5} md={7}>
                                
                            </Grid>
                            {
                                <>
                                    <Grid item xs={1}/>
                                    <Grid item xs={11}>
                                    {discountErr && (
                                        <span className={classes.error}>
                                            {discountErr}
                                        </span>
                                    )}
                                    </Grid>
                                </>
                            }
                        </>
                    :
                        <>
                            <Grid item xs={3} md={2}>
                                <FormControl className={classes.formControl}>
                                    <InputLabel htmlFor={"email"}>Coupon Code</InputLabel>
                                    <Input onChange={handleCouponInput} name={"discount"} id={"discount"} type="text"/>
                                    {
                                        discountErr && (
                                            <span className={classes.error}>
                                                {discountErr}
                                            </span>
                                        )
                                    }
                                    { showAddCouponButton &&
                                        <Button className={classes.couponButton} onClick={validateCouponCode} type="button">
                                            ADD
                                        </Button>
                                    }
                                </FormControl>
                            </Grid>
                            <Grid item xs={8} md={9}>
                            </Grid>
                        </>
            }
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11}>
                        <Typography variant={'body2'} className={classes.shippingInfo}>
                            Taxes will be calculated on checkout    
                        </Typography> 
                    </Grid>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={11} sm={5}>
                        <Button className={classes.checkoutButton} onClick={()=>{setStage("billing")}}>
                            Checkout
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>
                </Grid>
            </Fade>
        }
        {
            stage ==="billing" &&
            <BillingInformation products={products} cartSubtotal={cartSubtotal()} tax={totalTax()} total={totlAmount} setStage={setStage} discount={discountAmount} setCartProvince={setCartProvince}/>
        }
        {
            stage === "payment" &&
            <Checkout subtotal={subtotal} discount={discount ? discount.amount : 0} tax={totalTax()} total={totlAmount} setStage={setStage} products={products} user={lastOrder.billing}/>
        }
        {
            stage === "confirmation" && lastOrder &&
            <Confirmation lastOrder={lastOrder}/>
        }
        </>
        </Modal>
    )
}

export default CartModal