import React from 'react'
import { Grid, FormControl, FormHelperText, Input, InputLabel, Fade } from '@material-ui/core'
import useStyles from './styles'

const ProductSearch = ({onChange, start}) => {
    const classes = useStyles()
    const handleSearchInputChange = (event) => {
        onChange(event.target.value)
    }
    return (
        <Grid container className={classes.root} justify={"center"}>
            <Fade in timeout={1500}>
                <Grid item lg={6} md={8} sm={10}>
                    <FormControl className={classes.form}>
                        <InputLabel className={classes.label} htmlFor="search">Search</InputLabel>
                        <Input id="search" aria-describedby="my-helper-text" onChange={handleSearchInputChange} value={start}>
                        
                        </Input>
                        <FormHelperText id="my-helper-text">Enter name, brand etc.  Seperate search words by spaces - ex. Wine California</FormHelperText>
                    </FormControl>
                </Grid>
            </Fade>
        </Grid>
    )
}

export default ProductSearch
