import React from 'react'
import { Grid } from '@material-ui/core'
import useStyles from './styles'

const Youtube = ({id, autoplay=true}) => {
    const classes = useStyles()
    return (      
        <Grid container justify="center" className={classes.root}>
            <Grid item xs={12}>
            <iframe className={classes.video}
                src={"https://www.youtube-nocookie.com/embed/" + id + "?" + (autoplay ? "autoplay=1&" : null) + "controls=0&modestBranding&mute=1&loop=1&playlist=" + id}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope "
                allowFullScreen
        ></iframe>
      </Grid></Grid>
    )
}

export default Youtube
