import {makeStyles} from '@material-ui/core'

export default makeStyles((theme)=>({
    price:{
        margin:"50px 0px",
        border:"2px solid black",
        padding:"25px 35px",
        borderRadius:"10px"
    },
    third:{
        float: "left",
        width: "33%",
        padding: "0",
        margin: "0 0.166%"
    },
    submitContainer:{
        marginTop:"25px",
        marginBottom:"25px",
        '& button':{
            padding:"15px 30px",
            margin:"0px 10px",
            backgroundColor:"#620015",
            color:"white",
            fontFamily:"system-ui",
            transition:'all .25s',
            border:"2px solid black",
            '&:hover':{
                backgroundColor:"white",
                color:"#620015",
                boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
            }
        }
    },
    secured:{
        textAlign:"center"
    },
    walletButton:{
        minHeight:"80px"
    },
    circular:{
        color:"#620015"
    }
}))