import React from 'react'
import useStyles from './styles'
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem
  } from "@material-ui/core";
  import { Field } from "react-final-form";

const DropInput = ({field, province}) => {
    const classes = useStyles()
    return (
        <Field name={field.id} validate={field.validate}>
            {({ input, meta }) => (
                <FormControl className={classes.formControl}>
                    <InputLabel htmlFor={field.id}>{field.label}</InputLabel>
                    <Select onChange={field.onChange} value={province ? province : "Ontario"} defaultValue={"Ontario"}>
                        {
                            field.select.map((prov)=>(
                                <MenuItem value={prov}>
                                    {prov}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            )}
        </Field> 
    )
}

export default DropInput
