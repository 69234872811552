import React, {useContext} from 'react'
import {
    Grid,
    Typography,
    Button,
  } from "@material-ui/core";
  import { Form } from "react-final-form";
  import NormalInput from './normalInput/normalInput'
  import DropInput from './dropInput/dropInput'
  import RadioInput from './radioInput/radioInput'
  import { CartContext } from '../../../cart'
import useStyles from './styles'

const Billing = ({formFields, shippingOption, paymentOption, setStage, province}) => {
    const classes = useStyles()
    const { handleOrderRequest } = useContext(CartContext)
    const handleSubmit = (values) => {
      values.province = province
      values.paymentOption = paymentOption
      values.shippingOption = shippingOption
      handleOrderRequest(values)
    }
    return (
        <Grid item xs={12} md={6} className={classes.billing}>
        <Form
          onSubmit={(values) => {        
            handleSubmit(values)
          }}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={(e)=>{e.preventDefault(); handleSubmit(values)}}>
              <Grid container spacing={2} justify="center">
                <Grid item xs={12}>
                  <Typography variant={"h6"}>Billing</Typography>
                </Grid>
                {formFields.map((field) => {
                    if(field.hidden && 
                        (
                          (paymentOption !== 'online' && !field.select && !field.radioButtons) &&
                          (field.hidden.includes(shippingOption ? shippingOption : "hideOnNoShipping")) 
                          || 
                          field.hidden.includes(paymentOption ? paymentOption : "hideOnNoPayment")
                          )
                      )return null
                    return(
                  <Grid
                    key={field.id}
                    className={classes.field}
                    item
                    xs={field.size}
                  >
                      { !field.select && !field.radioButtons &&
                        <NormalInput field={field}/>
                    }
                    {
                        field.select && !field.radioButtons &&
                        <DropInput field={field} province={province}/>
                    }
                    {
                        field.radioButtons && !field.select &&
                        <RadioInput field={field}/>
                    }
                  </Grid>
                    )
                })}

              <Grid item xs={12}>
                  {
                      paymentOption &&
                      <Button type="submit" className={classes.submitButton}>
                        {paymentOption === "online" && "Continue to Payment"}
                        {paymentOption === "instore" && "Place Order"}
                      </Button>
                  }                  
              </Grid>
              </Grid>
            </form>
          )}
        ></Form>
      </Grid>
    )
}

export default Billing
