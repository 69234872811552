import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { Link } from 'gatsby'
import {Youtube} from '../index'
import useStyles from './styles'

const ProductVideoAd = ({data}) => {
    const classes = useStyles()
    return (
        <Grid className={classes.root} container>
            <Grid item xs={12}>
                <Youtube id={data.videoId}/>
            </Grid>
            <Grid className={classes.overlay} item>
                <Link 
                    to={'/store'}
                    className={classes.link}        
                    state={{searchFor:data.searchWords}}
                >
                    <Button 
                        variant={"contained"} 
                        color={"primary"}
                        className={classes.button}
                    >
                        SEE IN STORE
                    </Button>
                </Link>
            </Grid>
        </Grid>
        
    )
}

export default ProductVideoAd
