import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    billing:{
        backgroundColor:"white",
        marginTop:"50px",
        marginLeft:"25px"
    },
    formControl:{
        width:"100%",
        '& input':{

        }
    },
    summary:{
        marginTop:"50px",
        backgroundColor:"white"
    },
    summaryItems:{
        paddingBottom:"50px",
        marginBottom:"50px",
        borderBottom:"1px solid rgb(240,240,240)"
    },
    summaryItem:{
        padding:"2.5px 0px"
    },
    summaryText:{
        fontSize:"12px",
        textAlign:"right",
        paddingRight:"10px"
    },
    submitButton:{
        width:"100%",
        backgroundColor:"rgb(0,180,255)",
        color:"white",
        marginTop:"25px"
    },
    shippingOption:{
        '& span':{
            fontSize:"14px"
        },
        '& svg':{
            color:theme.palette.secondary.main
        }
    },
    radioButton:{
        color:"black !important"
    }
}))