import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        background:"radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(98,0,21,1) 82%)",
        minHeight:"100vh"
    },
    paper:{
        paddingTop:"25px",
        backgroundColor:"transparent"
    },
    title:{
        textAlign:"center",
        fontFamily:"Sonsie One",
        margin:"25px 0px",
        [theme.breakpoints.down("md")]:{
            fontSize:"30px"
        },
        [theme.breakpoints.down("md")]:{
            fontSize:"24px"
        }
    },
    menuListItems:{
        padding:"0px"
    },
    seriesDescription:{
        textAlign:"center",
        width:"60%",
        marginLeft:"20%",
        marginBottom:"25px"
    },
    itemCardContainer:{
        padding:"5px",
        width:"100%",
    },
    from:{
        marginLeft:"5px"
    },
    divider:{
        width:"75%",
        marginBottom:"15px"
    },
    button:{
        width:"100%",
        cursor:'pointer',
        backgroundColor:'transparent',
        border:'none'
    },
    itemCard:{
        minHeight:"225px",
        border:"5px solid " + theme.palette.secondary.main,
        borderBottom:"0px",
        boxShadow:"0px 0px 2px rgba(0,0,0,0.25)",
        padding:"10px"
    },
    borderBottom:{
        borderBottom:"5px solid " + theme.palette.secondary.main
    },
    accordion:{
        padding:"0px 0px",
        borderTop:"5px solid " + theme.palette.secondary.main,
        borderBottom:"5px solid " + theme.palette.secondary.main,
        boxShadow:"0px 0px 5px 0px rgb(100,100,100)",
        paddingBottom:"25px",
        marginBottom:"25px"
    },
    link:{
        textDecoration:"none",
        color:"black",
    },
    info:{
        padding:"10px"
    },
    icon:{
        color:"white",
        marginRight:"5px",
        '&:hover':{
            color:theme.palette.secondary.main
        }
    },
    iconButton:{
        width:"100%",
        padding:"15px 30px",
        borderRadius:"0px 0px 10px 10px",
        margin:"0px",
        backgroundColor:"#620015",
        color:"white",
        fontFamily:"system-ui",
        transition:'all .25s',
        border:"1px solid black",
        '&:hover':{
            backgroundColor:"white",
            color:"#620015",
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        },
        '&:hover svg':{
            color:theme.palette.secondary.main
        },
        '& p':{
            fontFamily:"system-ui",
            fontWeight:"500"
        },
        '&:hover p':{
            color:theme.palette.secondary.main
        },
        [theme.breakpoints.down('sm')]:{
            padding:"15px 10px",
            '& p':{
                fontSize:"12px",
            }
        }
    },
    cartButtonText:{
        '&:hover':{
            color:theme.palette.secondary.main
        }
    }
}))