import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme)=>({
    root:{
        minHeight:"800px",
    },
    title:{
        margin:"25px 0px",
        textAlign:"center",
    },
    subtitle:{
        
    },
    button:{
        backgroundColor:theme.palette.secondary.main,
        color:theme.palette.textSecondary.main,
        border:"none",
        padding:"15px 25px",
        marginTop:"25px",
        maxWidth:"250px"
    },
    iconButton:{
        width:"100%",
        padding:"15px 30px",
        borderRadius:"0px 0px 10px 10px",
        margin:"0px",
        marginBottom:"15px",
        backgroundColor:"#620015",
        color:"white",
        fontFamily:"system-ui",
        transition:'all .25s',
        border:"1px solid black",
        '&:hover':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        },
        '&:disabled p':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
        },
        '&:hover svg':{
            color:theme.palette.secondary.main
        },
        '&:hover p':{
            color:theme.palette.secondary.main
        },
        '& p':{
            fontFamily:"system-ui",
            fontWeight:"500"
        },
        [theme.breakpoints.down('sm')]:{
            padding:"15px 10px",
            '& p':{
                fontSize:"12px",
            }
        }
    },
    confirm:{
        marginTop:'25px',
        marginBottom:'25px',
        textAlign:"center"
    },
    divider:{
        margin:"25px 0px",
        width:"95%"
    },
    datepicker:{
        width:'100%',
        '& > .react-datepicker-wrapper':{
            width:"100%",
            textAlign:"center"
        },
        '& .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled':{
            display:"none"
        }
    },
    datePicked:{
        marginTop:"15px"
    },
    hidden:{
        display:"none"
    },
    upcomingAppointments:{
        width:"100%"
    },
    newApptButton:{
        width:"100%"
    },
    appointments:{
        backgroundColor:"rgba(0,0,0,0.05)"
    },
    datepickerContainer:{
        width:"100%",
    },
    selectedProducts:{
        display:"flex",
        flexDirection:"column"
    },
    row:{
        textAlign:"center",
        borderBottom:"1px solid rgba(0,0,0,0.15)",
        '& > div':{
            borderLeft:"1px solid rgba(0,0,0,0.15)",
            borderRight:"1px solid rgba(0,0,0,0.15)",
            display:"flex",
            justifyContent:"center",
            alignItems:"center"
        }
    },
    products:{
    },
    cancel:{
        fill:"rgba(255,0,0,0.25)",
        cursor:"pointer"
    },
    formControl:{
        display:"flex",
        justifyContent:"center",
    },
    heading:{
        marginTop:"15px",
        textDecoration:"underline",
        [theme.breakpoints.down('md')]:{
            marginLeft:"15px"
        }
    },
    duration:{
        marginTop:"25px",
        textAlign:"center"
    },
    purpose:{
        paddingLeft:"25px"
    },
    radioButton:{
        '& svg':{
        color:theme.palette.secondary.main + ' !important'
        }
    }
}))