import React, { useEffect, useRef, useState } from 'react'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { Grid, Fade } from '@material-ui/core'
import useStyles from './styles'

const Logo = ({fluid, backgroundFluid, altFluid}) => {
    const classes = useStyles()
    const imageContainerRef = useRef()
    const [activeImageIndex, setActiveImageIndex] = useState(0)

    useEffect(()=>{
        const flipTime = 7500
        const imageContainer = imageContainerRef.current 
        const activeElement = imageContainer.querySelector('.' + classes.active)
        if(activeElement)activeElement.classList.remove(classes.active)
         setTimeout(()=>{
             imageContainer.children[activeImageIndex].classList.add(classes.active)
         },250)
        setTimeout(()=>{
            setActiveImageIndex(activeImageIndex < imageContainer.children.length - 1 ? activeImageIndex + 1 : 0)
        }, flipTime)
    },[activeImageIndex, setActiveImageIndex, classes])
    return (
        <Fade in timeout={1500}>
            <BackgroundImage
                Tag="div"
                className={classes.root}
                fluid={backgroundFluid}
                backgroundColor={`#040e18`}
            >
                <Grid ref={imageContainerRef} className={classes.imagesContainer} container justify="center">
                    <Grid className={classes.imageContainer} item>
                        <Img className={classes.image} fluid={fluid} placeholderStyle={{ visibility: "hidden" }}/>
                    </Grid>
                    <Grid item className={`${classes.imageContainer} ${classes.alt}`}>
                        <Img className={classes.image} fluid={altFluid}/>
                    </Grid>
                </Grid>
            </BackgroundImage>
        </Fade>
    )
}

export default Logo