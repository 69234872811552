import React, {useState} from 'react'
import Gate from './gate/gate'
import Appointments from './appointments/appointments'
import useStyles from './styles'

const Admin = () => {
    const classes = useStyles()
    const [user, setUser] = useState(null)
    return (
        <div className={classes.root}>  
        {
            user
            ?
            <Appointments/>
            :
            <Gate setUser={setUser}/>
         }
         </div>
    )
}

export default Admin
