import { makeStyles } from "@material-ui/core/styles"

export default makeStyles((theme)=>({
    root:{
        backgroundSize:"cover",
        color:"black"
    },
    imagesContainer:{
        position:"relative"
    },
    imageContainer:{
        position:"relative",
        transform:'rotate3d(0,1,0,90deg)',
        transition:"transform .25s",
    },
    image:{
        width:'200px',
        margin:'25px',
    },
    active:{
        transform:'rotate3d(0,1,0,0deg)',
    },
    set:{
        transform:'rotate3d(0,1,0,0deg)',
        transition:'transform .25s',
    },
    flip:{
        transform:'rotate3d(0,1,0,-270deg)',
        transition:'transform .75s',
    },
    alt:{
        position:"absolute"
    }
}))
