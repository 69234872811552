import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        marginTop:"15px",
        padding:"10px",
    },
    title:{
        marginBottom:"25px",
    },
    headRow:{
        border:"1px solid " + theme.palette.secondary.main,
    },
    row:{
        border:"1px solid " + theme.palette.secondary.main,
        borderTop:"none"
    },
    new:{
        backgroundColor:"rgba(0,200,100,0.075)"
    },
    top:{
        borderTop:"1px solid " + theme.palette.secondary.main
    }
}))