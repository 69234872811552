import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        background:"radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(98,0,21,1) 82%)",
    },
    container:{
        backgroundColor:"white",
        border:"5px solid " + theme.palette.secondary.main,
        borderTop:"none"
    },
    imageContainer:{
        position:"relative",
        backgroundColor:"white"
    },
    image:{
        width:"100%"
    },
    overlay:{
        position:"absolute",
        zIndex:"1",
        color:"white",
        display:"flex",
        width:"100%",
        height:"100%",
        justifyContent:"center",
        alignItems:"center"
    },
    title:{
        fontSize:"80px",
        fontFamily:"Anton",
        WebkitTextStrokeWidth:"2px",
        WebkitTextStrokeColor:"black",
        textShadow:"0px 0px 15px black",
        [theme.breakpoints.down('sm')]:{
            fontSize:"50px"
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:"40px"
        }
    },
    articleContainer:{
        marginBottom:"50px",
        '& h1':{
            textAlign:"center"
        }
    }
}))