export const calculateTax = (amount, province) => { 
    const taxRates = {
        "alberta":{
            hst:0.05
        },
        "british columbia":{
            pst:0.07,
            gst:0.05
        },
        "manitoba":{
            pst:0.07,
            gst:0.05
        },
        "new brunswick":{
            hst:0.15
        },
        "newfoundland":{
            hst:0.15
        },
        "northwest territories":{
            gst:0.05
        },
        "nova scotia":{
            hst:0.15
        },
        "nunavat":{
            gst:0.05
        },
        "ontario":{
            hst:0.13
        },
        "prince edward island":{
            hst:0.15
        },
        "quebec":{
            qst:0.09975,
            gst:0.05
        },
        "saskatchewan":{
            gst:0.06,
            pst:0.05
        },
        "yukon":{
            gst:0.05
        }
    }
    let totalTax = 0
    const itemized = Object.keys(taxRates[province.toLowerCase()]).map((taxName)=>{
        const tax = (amount * parseFloat(taxRates[province.toLowerCase()][taxName])).toFixed(2)
        totalTax = totalTax + parseFloat(tax)
        return {name:taxName, rate:(taxRates[province.toLowerCase()][taxName] * 100) + "%", amount:tax}
    }
    )
    return {itemized:itemized, total:totalTax.toFixed(2)}
}
export default calculateTax
