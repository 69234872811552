import React from 'react'
import { Grid } from '@material-ui/core'
import ConfirmationTitle from './confirmationTitle/confirmationTitle'
import DeliveryDetails from './deliveryDetails/deliveryDetails'
import OrderConfirmation from './orderConfirmation/orderConfirmation'
import OrderDetails from './orderDetails/orderDetails'
// import OrderNumber from './orderNumber/orderNumber'
import OrderSummary from './orderSummary/orderSummary'
import PickupDetails from './pickupDetails/pickupDetails'
import ShippingDetails from './shippingDetails/shippingDetails'
import useStyles from './styles'

const testOrder = {
    "billing" : {
      "address" : "1234",
      "apt" : "Fake St",
      "city" : "Fakeville",
      "email" : "test@test.ca",
      "name" : "Test test",
      "paymentOption" : "online",
      "postal" : "P0D4F4",
      "province" : "Ontario",
      "shippingOption" : "ship"
    },
    "invoiceId" : "KC-103",
    "products" : [ {
      "name" : "Canadian Blonde Coopers Beer Kit",
      "price" : 22.99,
      "quantity" : 1
    } ],
    "status" : "payment-pending",
    "subtotal" : "22.99",
    "taxes" : {
      "itemized" : [ {
        "amount" : "2.99",
        "name" : "hst",
        "rate" : "13%"
      } ],
      "total" : "2.99"
    }
  }

const Confirmation = ({lastOrder=testOrder}) => {
    const classes = useStyles()
    const { products, discount, billing, subtotal, taxes } = lastOrder
    return (
        <Grid className={classes.root} container justify="center">
            <Grid item>
                <ConfirmationTitle text={"Thank you for shopping with us!"}/>
                <OrderConfirmation emailAddress={billing.email}/>
                {/* <OrderNumber invoiceId={invoiceId}/> */}
                <OrderSummary subtotal={subtotal} discount={discount} taxes={taxes}/>
                {{pickup:<PickupDetails/>, delivery:<DeliveryDetails address={billing}/>, ship:<ShippingDetails address={billing}/>}[billing.shippingOption]}
                <OrderDetails products={products}/>
            </Grid>
        </Grid>
    )
}

export default Confirmation
