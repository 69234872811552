const validatePrice = async (product, price)=>{
    const productUrl = '/api/validateProductPrice'
    return await fetch(productUrl, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify({
            product:product,
            price:price
        })
    })
    .then(response=>response.json())
    .then(response=>{
        return response.priceValid
    })
}
export default validatePrice