import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme)=>({
    inputContainer:{
        width:"80%",
    },
    formControl:{
        width:"100%",
        marginBottom:"25px",
        [theme.breakpoints.down('md')]:{
            marginBottom:"0px"
        }
    },
    form:{
        width:"100%"
    },
    icon:{
        marginTop:"20px",
        width: "100%",
        color:"#620015"
    },
    label:{
        marginLeft:"8.333%",
        color:theme.palette.secondary.main
    },
    input:{
        width:"95%",
        backgroundColor:"transparent",
        border:"none",
        borderBottom:"1px solid lightgrey",
        height:"40px",
        fontSize:"18px",
        '&:focus':{
            outline:"none"
        }
    },
    error:{
        color:"red",
        height:"15px",
        fontSize:'12px',
        textAlign:'center'
    },
    submitButtonContainer:{
        display:"flex",
        justifyContent:"center",
        marginTop:"25px"
    },
    submitButton:{
        width:"250px",
        padding:"15px 30px",
        borderRadius:"0px 0px 10px 10px",
        margin:"10px",
        backgroundColor:theme.palette.secondary.main,
        color:"white",
        fontFamily:"system-ui",
        transition:'all .25s',
        border:"1px solid black",
        '&:hover':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        },
        '&:disabled p':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
        },
        '&:hover svg':{
            color:theme.palette.secondary.main
        },
        '&:hover p':{
            color:theme.palette.secondary.main
        },
        '& p':{
            fontFamily:"system-ui",
            fontWeight:"500"
        },
        [theme.breakpoints.down('sm')]:{
            padding:"15px 10px",
            '& p':{
                fontSize:"12px",
            }
        },
        
    },
    register:{
        padding:"25px 0px",
        textAlign:"center",
        '& button':{
            cursor:"pointer",
            padding:"5px 10px",
            borderRadius:"5px",
            backgroundColor:theme.palette.secondary.main,
            color:"white",
            fontFamily:"system-ui",
            transition:'all .25s',
            border:"1px solid black",
            '&:hover':{
                backgroundColor:"white",
                color:theme.palette.secondary.main,
                boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
            },
        }
    },
    resetConfirmation:{
        textAlign:"center"
    }
}))