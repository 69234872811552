import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        overflowX:"hidden",
        background:"radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(98,0,21,1) 82%)"
    },
    title:{
        backgroundColor:theme.palette.secondary.main,
        width:"100%",
        textAlign:"center",
        color:theme.palette.textPrimary.main,
        padding:"15px 0px",
        [theme.breakpoints.down('sm')]:{
            '& > h4':{
                fontSize:"25px"
            }
        }
    },
    subtitle:{
        padding:"25px 0px",
        [theme.breakpoints.down('sm')]:{
            fontSize:"20px",
            padding:"10px 25px",
            textAlign:"center"
        }
        
    },
    divider:{
        margin:"25px 0px",
        width:"90%"
    },
    // loginContainer:{
    //     position:"relative",
    //     margin:"0px 25px"
    // },
    loginContainer:{
        margin:"50px 0px",
        minHeight:"500px",
        backgroundColor:"rgba(240,240,240,0.75)",
        border:"5px solid " + theme.palette.secondary.main,
        borderRadius:"10px",
        display:"flex",
        alignItems:"center"
    },
    loginField:{
        position:"relative",
        width:"100%"
    },
    passwordField:{
        position:"absolute"
    },
    signup:{
        margin:"0px 25px"
    },
    signin:{
        margin:"0px 25px"
    },
    hide:{
        opacity:0
    },
}))