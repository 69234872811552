import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    testimonials:{
        position:"relative",
        height:"450px",
        paddingTop:"25px",
        [theme.breakpoints.down('sm')]:{
            height:"350px"
        },
    },
    logo:{
        width:"200px",
        paddingTop:"25px",
        paddingBottom:"50px",
        [theme.breakpoints.down('sm')]:{
            width:"150px",
            paddingTop:"50px"
        }
    },
    item:{
        position:"absolute",
    },
    quote:{
        height:"350px",
        textAlign:"center",
        opacity:"0",
        transition:"opacity 1s",
        display:"flex",
        alignItems:"center",
        [theme.breakpoints.down('sm')]:{
            fontSize:"35px",
            height:"275px"
        },
        [theme.breakpoints.down('xs')]:{
            fontSize:"24px",
            height:"275px"
        }
    },
    author:{
        opacity:"0",
        textAlign:"center",
        transition:"opacity .5s linear .5s",
        paddingTop:"15px",
        [theme.breakpoints.down('xs')]:{
            fontSize:"22px"
        }
    },
    active:{
        '& h3':{
            opacity:"1"
        },
        '& h5':{
            opacity:'1'
        }
    }
}))