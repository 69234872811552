import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        position:'relative'
    },
    overlay:{
        position:"absolute",
        top:"75%",
        width:"100%",
        display:"flex",
        justifyContent:"center"
    },
    link:{
        display:"flex",
        width:"100%",
        justifyContent:"center",
        textDecoration:"none",
        '&:hover':{
            textDecoration:"none"
        }
    },
    button:{
        padding:"15px 30px",
        margin:"0px 10px",
        marginLeft:"50%",
        backgroundColor:theme.palette.secondary.main,
        color:theme.palette.textSecondary.main,
        transition:'all .25s',
        border:"2px solid white",
        '&:hover':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
            borderColor:theme.palette.textPrimary.main,
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        },
        [theme.breakpoints.down('md')]:{
            marginTop:"15px",
            padding:"10px 15px"
        },
    }
}))