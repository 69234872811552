import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme)=>({
    root:{
        backgroundColor:"white"
    },
    title:{
        textAlign:"center",
        marginTop:"25px",
        color:theme.palette.secondary.main
    },
    divider:{
        width:"82%",
        height:"2px",
        margin:"25px 0px 40px 0px",
        backgroundColor:theme.palette.secondary.main
    },
    price:{
        textAlign:"center",
        marginBottom:"50px"
    },
    image:{
        position:"relative",
        width:"300px",
        height:"300px",
        '& >.gatsby-image-wrapper':{
            height:"100%"
        },
        '& img':{
            height:"100%",
            objectFit:"contain !important"
        }
    },
    description:{
        width:"80%",
        textAlign:"center",
        marginBottom:"50px",
        backgroundColor:"white"
    },
    buttonContainer:{
        display:"flex",
        justifyContent:"center"
    },
    button:{
        padding:"15px 30px",
        margin:"0px 10px",
        width:"100%",
        backgroundColor:theme.palette.secondary.main,
        color:theme.palette.textSecondary.main,
        transition:'all .25s',
        border:"2px solid white",
        '&:hover':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
            borderColor:theme.palette.textPrimary.main,
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        }
    },
    iconButton:{
        width:"250px",
        padding:"15px 30px",
        borderRadius:"0px 0px 10px 10px",
        margin:"10px",
        backgroundColor:"#620015",
        color:"white",
        fontFamily:"system-ui",
        transition:'all .25s',
        border:"1px solid black",
        '&:hover':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
            boxShadow:"1px 2px 5px rgba(0,0,0,0.75)"
        },
        '&:disabled p':{
            backgroundColor:"white",
            color:theme.palette.secondary.main,
        },
        '&:hover svg':{
            color:theme.palette.secondary.main
        },
        '&:hover p':{
            color:theme.palette.secondary.main
        },
        '& p':{
            fontFamily:"system-ui",
            fontWeight:"500"
        },
        [theme.breakpoints.down('sm')]:{
            padding:"15px 10px",
            '& p':{
                fontSize:"12px",
            }
        }
    },
    longDescription:{
        marginBottom:"50px"
    },
    descriptionContainer:{
        minWidth:"300px"
    }
}))