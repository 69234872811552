import React from 'react'
import { Grid, Typography, Divider, Fade } from '@material-ui/core'
import Img from 'gatsby-image'
import { Youtube } from '../index'
import useStyles from './styles'

const PerfectPair = ({data}) => { console.log("data", data)
    const classes = useStyles()
    const Episode = ({video}) => (
        <Grid item xs={12}>
            <Divider className={classes.divider}/>
            <Typography className={classes.videoTitle} variant={"h6"}>
                {video.title}
            </Typography>
            <Typography className={classes.videoDescription} variant={"body2"}>
                {video.description}
            </Typography>
            <div className={classes.video}>
                <Youtube id={video.videoId} autoplay={false}/>
            </div>
        </Grid>
    )
    return (
        <Fade in timeout={1000}>
            <Grid className={classes.root} container direction="column" alignItems="center">
                <Grid item xs={12} sm={10} md={8}>
                    <Grid className={classes.container} container direction="column" alignItems="center">
                        <Grid className={classes.imageContainer} item>
                            <Img className={classes.image} fluid={data.headerImage.sizes}/>
                        </Grid>
                        <Grid className={classes.title} item>
                            <Typography variant={'h4'}>
                                {data.title}
                            </Typography>
                        </Grid>
                        <Grid className={classes.episodes} item xs={12}>
                            <Grid container direction="column">
                            {
                                data.videos.map((video)=>(
                                    <Episode key={video.id} video={video}/>
                                ))
                            }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>                                                                 
        </Fade>
    )
}

export default PerfectPair
