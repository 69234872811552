import React from 'react'
import {Grid, Fade} from '@material-ui/core'
import Product from './Product/product'
import useStyles from './styles'

const Products = ({products}) => {
    const classes = useStyles();
    if(!products)return(<div>Loading...</div>)
    return(
        <Fade in timeout={1600}>
            <Grid container className={classes.root} justify="center" spacing={1}>
                {
                    products.map((product)=>{
                        return(<Grid item key={product.id} xs={12} sm={6} md={4} lg={3}>
                            <Product product={product}/>
                        </Grid>)
                    })
                }
            </Grid>
        </Fade>
    )
}

export default Products;