export const createOrder = (cart, discount, billing, subtotal, taxes, status) => {
    const products = cart.map((product)=>(
        {name:product.name, quantity:product.quantity, price:product.price}
    ))
    return {
        products:products,
        billing:billing,
        subtotal:subtotal,
        discount:discount,
        taxes:taxes,
        status:status
    }
}

export default createOrder