import React, {useState, useEffect, createContext} from 'react'
import CartModal from './cartModal/cartModal'
import useFirebase from "../../hooks/useFirebase"
import taxCalculator from '../../utilities/taxCalculator'
import createOrder from '../../utilities/createOrder'

const CartContext = createContext({
    products:[],
    addToCart:()=>{},
    setCartOpen:()=>{},
    cartQuantity:0,
    toggleCart:()=>{},
    test:"test complete",
    setTaxRate:()=>{},
    subTotal:0.00,
    totalAmount:0.00,
    handleOrderRequest:()=>{},
    setCartProvince:()=>{},
    handlePaymentStatusToPaid:()=>{},
})

const Cart = ({children}) => {
    const [cartState, setCartState] = useState([])
    const [stage, setStage] = useState("viewcart")
    const [lastOrderId, setLastOrderId] = useState(null)
    const [lastOrder, setLastOrder] = useState(null)
    const [cartOpen, setCartOpen] = useState(false)
    const [province, setCartProvince] = useState("")
    const [discount, setDiscount] = useState(null)
    const [discountError, setDiscountError] = useState(null)
    const firebase = useFirebase()
    const handleOrderRequest = (billing) => {
       const subtotal = subTotal()
       const tax = totalTax()
       const order = createOrder(cartState, (discount && subtotal > discount.minimumPurchase ? {code:discount.code, amount:discount.amount} : null), billing, subtotal, tax, billing.shippingOption === 'online' ? "payment-online" : "payment-pending")
             firebase
       .database()
       .ref("orders/")
       .push({
         order:order
       })
       .then((result) => {
         setLastOrderId(result.key)
         order.invoiceId = result.key
         setLastOrder(order)
         if(billing.paymentOption === 'online'){setStage('payment')}
         else{setStage('confirmation')}
       })
       .catch((error) => {
         console.log("Something went wrong", error);
       });

    }
    const handlePaymentStatusToPaid = () => {
        firebase
        .database()
        .ref("orders/" + lastOrderId + "/order/")
        .update({
            status:"payment-received"
        })
    }
    const addProduct = (product)=>{
        product.quantity = 1
        setCartState([...cartState, product])
    }
    const cartQuantity = (
        cartState.length
    )
    const changeQuantity = (product, increment) => {
        const newProduct = [...cartState].filter((stateProduct)=>{         
            return stateProduct.name === product.name
        })[0]
        const newQuantity = parseInt(newProduct.quantity) + parseInt(increment)
        newProduct.quantity = newQuantity ? newQuantity : newProduct.quantity
        const stateCopy = [...cartState].filter((stateProduct)=>(
            stateProduct.name !== product.name
        ))
        setCartState([...stateCopy, newProduct])
    }

    const removeProduct = (product) => 
        {
            const productToRemove = cartState.find((cart)=>(
                product.name === cart.name
            ))
            productToRemove.inCart = false
            setCartState(cartState.filter((cart)=>(
                product.name !== cart.name
            ))
            )
        }
    const toggleCart = () => {
        setCartOpen(!cartOpen)
    }
    const subTotal = () => {
        let subTtl = 0
        cartState.forEach((product)=>{
            subTtl += product.price * product.quantity
        })
        return subTtl.toFixed(2)
    }
    const totalTax = () => {
        if(!province)return
        let taxableSubtotal = 0
        cartState.forEach((product)=>{
            if(!product.taxExempt)taxableSubtotal += product.price * product.quantity
        })
        return taxableSubtotal > 0 ? taxCalculator(taxableSubtotal, province) : {itemized:[], total:0}
    }
    const totalAmount = () => (
        province ?
            (parseFloat(subTotal()) + parseFloat(totalTax().total) - (discount ? discount.amount : 0)).toFixed(2)
            :
            0
    )
    useEffect(()=>{
        !cartOpen && setStage('viewcart')
    },[cartOpen])
    useEffect(()=>{
        if(cartState.length === 0 && stage !== 'confirmation')setCartOpen(false)
    },[cartState, stage])
    const cartContextValues = {
        products:cartState,
        addToCart:addProduct,
        setCartOpen:setCartOpen,
        cartQuantity:cartQuantity,
        toggleCart:toggleCart,
        totalTax:totalTax,
        subTotal:subTotal,
        totalAmount:totalAmount,
        handleOrderRequest:handleOrderRequest,
        setCartProvince:setCartProvince,
        handlePaymentStatusToPaid:handlePaymentStatusToPaid
    }
    return (
        <CartContext.Provider value={cartContextValues}>
            <CartModal open={cartOpen} 
            products={cartState} 
            setCartOpen={setCartOpen} 
            changeQuantity={changeQuantity} 
            removeProduct={removeProduct} 
            cartSubtotal={subTotal} 
            setCartProvince={setCartProvince} 
            totalTax={totalTax}
            totalAmount={totalAmount}
            lastOrder={lastOrder}
            setCartState={setCartState}
            stage={stage}
            setStage={setStage}
            setDiscount={setDiscount}
            discountError={discountError}
            setDiscountError={setDiscountError}
            discount={discount}/>
            {children}
        </CartContext.Provider>
    )
    
}

export default Cart
export {CartContext}
