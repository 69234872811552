import React, {lazy, Suspense} from 'react'
import {
    createMuiTheme,
    MuiThemeProvider
  } from "@material-ui/core/styles";
  import useStyles from './styles'
const Footer = lazy(async()=>await import('../../components/footer/footer'))
const theme = createMuiTheme({
    palette:{
        primary:{
            main:'#000000',
            dark:'#000000',
            light:'#000000',
            contrastText:"#ffffff"
        },
        secondary:{
            main:'#620015',
            contrastText:'#ffffff'
        },
        textPrimary:{
            main:'#DFF8EB'
        },
        textSecondary:{
            main:'#ffffff'
        },
        button:{
            main:'#000000'
        }
    },
    props:{
        layout:{
            backgroundImage:'url("/kcwabLogo.png")'
        }
    }
})
theme.overrides = {
    ...theme.overrides,
    MuiCard:{
        ...theme.MuiCard,
        root:{
            ...theme.root,
            backgroundColor:theme.palette.textSecondary.main,
        }
    },
    MuiCardActions:{
        ...theme.MuiCardAction,
        root:{
            ...theme.root,
            backgroundColor:theme.palette.secondary.main,
            color:theme.palette.textPrimary.main
        }
    },
    MuiIconButton:{
        ...theme.MuiIconButton,
        root:{
            ...theme.root,
            color:theme.palette.button.main,
            backgroundColor:'transparent'
        },
    },
    MuiCardContent:{
        ...theme.MuiCardContent,
        root:{
            ...theme.root,
            minHeight:'150px'
        }
    },
    MuiSvgIcon:{
        ...theme.MuiSvgIcon,
        root:{
            ...theme.root,
            color:"#ffffff"
        }
    },
    MuiTypography:{
        ...theme.MuiTypography,
        colorTextSecondary:{
            color:'#ffffff'
        }
    },
    MuiDialog:{
        ...theme.MuiDialog,
        root:{
            '& .MuiSvgIcon-root':{
                color:"black"
            }
        }
    }
}


const Layout = ({children}) => {
    const classes = useStyles()
    return (    
        <div className={classes.root}>
            <MuiThemeProvider theme={theme}>
                {children}
                <Suspense fallback={<div></div>}>
                    <Footer />
                </Suspense>
            </MuiThemeProvider>
        </div>
    )
}

export default Layout
