import React, {useState} from 'react'
import { Grid, Fade, Button} from '@material-ui/core'
import { ArrowBackIos as ArrowBack, ArrowForwardIos as ArrowForward } from '@material-ui/icons'
import { Link } from 'gatsby'
import useStyles from './styles'

const MobileMenu = ({onClick, currentPage, setMenuOpen}) => {
    const classes = useStyles()
    const [subMenu, setSubMenu] = useState(null)
    const menuItems = [
        {
            display:"Home",
            to:"/"
        },
        {
            display:"Wine",
            subMenu: [
                {
                  display: "Craft Some Wine",
                  to: "/make-wine",
                },
                {
                  display: "Wine Menu",
                  to: "/wine-list",
                },
                {
                    display: "Wine Kits",
                    to:"/store",
                    state:{
                      searchFor:"Wine Kits"
                    }
                },
                {
                  display: "Wine Ingredients",
                  to:"/store",
                  state:{
                    searchFor:"Wine Ingredients"
                  }
                },
                {
                  display: "The Winemaking Process",
                  to: "/wine-making",
                },
                {
                  display: "Wine Troubleshooting",
                  to:"/wine-troubleshooting"
                },
              ],
        },
        {
            display:"Beer",
            subMenu:[
                {
                    display:"Craft Some Beer",
                    to:"/craft-beer",
                    cta:true
                },
                {
                    display:"Beer Menu",
                    to:"/beer-list"
                },
                {
                    display:"Beer Ingredients",
                    to:"/store",
                    state:{
                      searchFor:"Beer Ingredients"
                    }
                  },
                  {
                    display:"Beer Kits",
                    to:"/store",
                    state:{
                      searchFor:"Beer Kits"
                    }
                  },
                  {
                    display:"How to Craft Beer",
                    to:"/beer-crafting"
                  },
                  {
                      display:"Yeast",
                      to:"/store",
                      state:{
                          searchFor:"Beer Yeast"
                      }
                  },
                  {
                    display:"Grain",
                    to:"/store",
                    state:{
                      searchFor:"Grain"
                    }
                  },
                  {
                      display:"Hops",
                      to:"/store",
                      state:{
                          searchFor:"Hops"
                      }
                  }               
            ]
        },
        {
            display:"Other Brews",
            subMenu:[
                {
                    display:"Cider",
                    to:"/store",
                    state:{
                        searchFor:"Cider"
                    }
                }
            ]
        },
        {
            display: "equipment",
            subMenu: [
                {
                  display:"Bottling",
                  to:"/store",
                  state:{
                    searchFor:"Bottling"
                  }
                },
                {
                  display:"Distilling",
                  to:"/store",
                  state:{
                    searchFor:"Distilling"
                  }
                },
                {
                  display: "Fermenting",
                  to: "/store",
                  state:{
                      searchFor:"Fermenting"
                  }
                },
                {
                  display:"Filtering",
                  to:"/store",
                  state:{
                      searchFor:"Filtering"
                  }
                },
                {
                  display:"Kegging",
                  to:"/store",
                  state:{
                    searchFor:"Kegging"
                  }
                },
                {
                  display:"Sanitizing",
                  to:"/store",
                  state:{
                      searchFor:"Sanitizing"
                  }
                },
                {
                  display:"Siphoning",
                  to:"/store",
                  state:{
                    searchFor:"Siphoning"
                  }
                },
                {
                  display:"Testing",
                  to:"/store",
                  state:{
                    searchFor:"Testing"
                  }
                },
              ]
          },
        {
            display:"University",
            subMenu:[
                {
                    display:"The Perfect Pair Cooking Series",
                    to:"/university/the-perfect-pair-cooking-series"
                }
            ]
        },
    ]
    const MenuItem = ({item}) => (
        <Grid item>
            {item.display}
        </Grid>
    )
    const handleEquipmentClicked = (e, value) => {
        e.preventDefault()
        setMenuOpen(false)
        onClick(value.state.searchFor)
    }
    return (
        <Grid container direction="column" spacing={2} alignItems="center" className={classes.root}>
            <Fade in timeout={1500}>
            <div className={classes.wine}>
            {
                menuItems.map((item)=>(
                    subMenu
                    ?
                        subMenu === item.display
                        ?
                        <>
                            <Grid  className={classes.link} item>
                                <Button onClick={()=>{setSubMenu(null)}}>
                                    <ArrowBack className={`${classes.arrow} ${classes.back}`}/> Go Back
                                </Button>
                            </Grid>
                            {item.subMenu.map((subItem)=>(
                                <Grid item className={classes.link}>
                                    <Link onClick={currentPage === subItem.to && subItem.to.includes("store") && subItem.state ? (e)=>{handleEquipmentClicked(e, subItem)} : null} to={subItem.to} state={subItem.state}>
                                        <Button>
                                            {subItem.display}
                                        </Button>
                                    </Link>
                                </Grid>
                            ))}
                        </>
                        : null
                    :
                        item.to
                        ?
                            <Grid item className={classes.link}>
                                <Link to={item.to}>
                                    <Button>
                                        <MenuItem item={item}/>
                                    </Button>
                                </Link>
                            </Grid>
                        :
                            <Grid item className={classes.link}>
                                <Button  onClick={()=>{setSubMenu(item.display)}}>
                                    <MenuItem item={item}/><ArrowForward className={classes.arrow} />
                                </Button>
                            </Grid>
                ))
            }
            <Grid  className={classes.link} item>            
            </Grid>
            </div></Fade>
        </Grid>
    )
}

export default MobileMenu
