import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Helmet from 'react-helmet'

function SEO({ title, schema, children }) { 
  const { site, datoCmsFaviconMetaTags, datoCmsSite } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            keywords
            lang
          }
        }
        datoCmsSite{
          globalSeo{
            fallbackSeo{
              title
              description
            }
          }
        }
        datoCmsFaviconMetaTags {
            tags
          }
      }
    `
  )
  const keywords = site.siteMetadata.keywords
  const metaDescription = datoCmsSite.globalSeo.fallbackSeo.description
  const defaultTitle = site.siteMetadata?.title || title
  const lang = site.siteMetadata.lang
  const tags = datoCmsFaviconMetaTags.tags
  const renderedTags = tags.map((tag)=>(
        {
            link:<link key={tag.attributes.rel + '-' + tag.attributes.sizes} type="image/x-icon" sizes={tag.attributes.sizes} rel={tag.attributes.rel} href={tag.attributes.href}/>, 
            meta:<meta key={tag.attributes.rel + '-' + tag.attributes.sizes} name={tag.attributes.name}content={tag.attributes.content} />
        }[tag.tagName]
    ))
  renderedTags.push(<link rel="canonical" href="https://www.kenoracraft.ca"></link>)
  renderedTags.push(<meta name="description" content={metaDescription}/>)
  const schemaTag = schema ? 
    (<script type="application/ld+json">
        {schema}
    </script>)
    :
    null
  return (
      <>
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={defaultTitle}
      titleTemplate={title ? `${title} | ${defaultTitle}` : defaultTitle}
      meta={[
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: defaultTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
      ]}
    >
        {renderedTags}
        {schemaTag && schemaTag}
    </Helmet>
        {children}</>
  )
}
export default SEO