import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import useStyles from './styles'

const PickupDetails = ({address}) => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Typography variant={'h5'}>
                Pickup Details
            </Typography>
            <Grid container>
                <Grid item xs={3}>
                    <Typography variant={'body2'}>
                        Pickup Address
                    </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Typography variant={'body2'}>
                        608 B, 4th Street Keewatin, Ontario               
                    </Typography>
                </Grid>
            </Grid>
        </div>
    )
}

export default PickupDetails
