import React from 'react'
import useStyles from './styles'
import {
    FormControl,
    InputLabel,
    Input,
  } from "@material-ui/core";
  import { Field } from "react-final-form";

const NormalInput = ({field}) => {
    const classes = useStyles()
    return (
        <Field name={field.id} validate={field.validate}>
            {({ input, meta }) => (
                <FormControl className={classes.formControl}>
                <InputLabel htmlFor={field.id}>
                    {field.label}
                </InputLabel>
                <Input
                    className={classes.input}
                    {...input}
                    name={field.id}
                    id={field.id}
                    onBlur={field.onBlur}
                />
                <span className={classes.error}>
                    {meta.error && meta.touched && (
                    <span>{meta.error}</span>
                    )}
                </span>
                </FormControl>
            )}
        </Field>  
    )
}

export default NormalInput