import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        position:"fixed",
        top:"0",
        left:"0",
        width:"100vw",
        height:"100vh",
        backgroundColor:"rgba(0,0,0,0.75)",
        zIndex:"1101"
    },
    popup:{
        position:'relative',
        width:'500px',
        height:'500px',
        backgroundColor:theme.palette.secondary.main,
        border:"10px dashed white",
        '&  > div':{
            height:"100%"
        },
        [theme.breakpoints.down("xs")]:{
            width:"350px"
        }
    },
    closeButton:{
        position:'absolute',
        right:'0',
        top:'0'
    },
    title:{
        color:"white",
        display:"flex",
        textAlign:"center",
        marginTop:"50px",
        flex:'2',
        '& .MuiTypography-root':{
            fontFamily:"Sonsie One"
        }
    },
    formControl:{
        marginTop:"25px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    input:{
        flex:"1",
        marginBottom:"25px",
        color:"white",
        width:"350px",
        '& label':{
            color:"white"
        },
        '& label.Mui-focused':{
            color:"white"
        },
        '& .MuiInput-underline:before':{
            borderBottom:"2px solid white"
        },
        '& .MuiInput-underline:after':{
            borderBottom:"2px solid white"
        },
        '& input':{
            width:"350px",
            color:"white"
        },
        [theme.breakpoints.down('xs')]:{
            '& input':{
                width:"300px"
            }
        }
    },
    privacy:{
        flex:"1"
    },
    submit:{
        backgroundColor:"white",
        width:"100px",
        flex:"1",
        marginTop:"10px",
        '&:hover':{
            backgroundColor:"white",
            borderColor:"white"
        }
    },
    error:{
        width:"100%",
        paddingTop:"5px",
        textAlign:"center"
    },
    thankyou:{
        fontFamily:"Sonsie One",
        color:"white",
        marginBottom:"15px",
        padding:"0px 25px",
        textAlign:"center"
    }
}))