import React, {useState, useEffect} from 'react'
import { Typography, IconButton} from '@material-ui/core'
import { Print } from '@material-ui/icons'
import useStyles from './styles'

const OrderConfirmation = ({emailAddress}) => {
    const classes = useStyles()
    const [printMode, setPrintMode] = useState(false)
    useEffect(()=>{
        let timeout
        if(printMode && window){
            window.print()
            timeout = setTimeout(()=>{console.log("SETTING PRINT MODE TRUE"); setPrintMode(false)},100)
        }
        return ()=>{clearTimeout(timeout)}
    },[printMode])
    return (
        <div className={classes.root}>
            <Typography variant={"h6"}>
                Order Confirmation
            </Typography>
            <Typography variant={"body2"} gutterBottom>
                Your order has been successfully verified, below are the details of your order.
            </Typography>
            <Typography variant={"body2"} gutterBottom>
                <strong>Your order information will be emailed to: </strong>{emailAddress}
            </Typography>
            <Typography variant={"body2"} gutterBottom>
                Please come back soon
            </Typography>
            { 
                !printMode && 
                    <IconButton className={classes.printButton} onClick={()=>{setPrintMode(true)}}>
                        <Print/>
                    </IconButton>
            }
        </div>
    )
}

export default OrderConfirmation
