const paymentRequest = async (amount, currency, nonce, user) => {
    const url = '/api/createPayment'
    return await fetch(url, {
        method:'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body:JSON.stringify({
            square:{
                amount:amount,
                currency:currency,
                nonce:nonce
            },
            billing:user
        })
    })
    .then(response=>response.json())
    .then(response=>{ 
        return response.message
    })
}

export default paymentRequest