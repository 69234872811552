import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        backgroundColor:theme.palette.primary.main,
        textAlign:"center",
        paddingBottom:"25px"
    },
    products:{
        width:"98%",
        marginLeft:"1%"
    },
    title:{
        color:"white",
        padding:"25px 0px"
    }
}))