import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme)=>({
    container:{
        background:"radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(98,0,21,1) 82%)",
    },
    dataContainer:{
        background:"white",
        border:"5px solid " + theme.palette.secondary.main,
        padding:"0px 50px 50px 50px",
        '& h2':{
            fontSize:"30px"
        },
        '& p':{
            fontSize:"20px"
        }
    }
}))