import { makeStyles } from '@material-ui/core'

export default makeStyles((theme)=>({
    root:{
        // border:"5px solid " + theme.palette.secondary.main,
        border:"10px solid white",
        width:"95%",
        // width:"80%",
        marginLeft:"2.5%",
        backgroundColor:"white",
        color:"black",
        position:"fixed",
        zIndex:2,
        boxShadow:"0px 5px 5px rgba(0,0,0,0.5)",
        borderRadius:"0px 0px 55% 55%",
        overflow:"hidden",
    },
    wine:{
        width:"100%",
        backgroundColor:"white",
        marginTop:"50px"
    },
    link:{
        display:"flex",
        justifyContent:"center",
        padding:"10px 0px",
        // color:"black",
        backgroundColor:theme.palette.secondary.main,
        width:"100%",
        minHeight:"50px",
        '& a':{
            textDecoration:"none",
            width:"90%",
            color:"white",
        },
        '& a button':{
            width:"100%",
            color:"white"
        },
        '& button':{
            width:"90%",
            color:"white"
        }
    },
    arrow:{
        fill:"white",
        position:"absolute",
        height:"75%",
        right:"30px"
    },
    back:{
        right:"auto",
        left:"0"
    }
}))