import React, {useContext} from "react";
import {CartContext} from '../../../cart/cart'
import slugify from '@sindresorhus/slugify'
import {
  Card,
  CardContent,
  Divider,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { AddShoppingCart, ShoppingCart, Label } from "@material-ui/icons";
import useStyles from "./styles";

const ProductCard = ({ product }) => {
  const { addToCart, setCartOpen } = useContext(CartContext)
  const classes = useStyles();
  const priceWithSymbol = "$" + product.price.toFixed(2);
  const salePriceWithSymbol = "$" + product.salePrice;
  const url = '/store/product/' + slugify(product.name)
  const CardMedia = () => (
    <div className={classes.media}>
      <Img fluid={product.image.sizes} className={classes.gatsbyImage} loading="eager" />
      { product.salePrice && <Label className={classes.saleTag}/>}
    </div>
  );
  return (

      <Card className={classes.product}>
          <CardMedia />
          <CardContent>
              <Link
              className={classes.link}
              to={url}
            >
            <Grid container className={classes.cardContent}>
              <Grid item xs={12} className={classes.title}>
                <Typography variant={"h6"} gutterBottom align="center" color={"secondary"}>
                  {product.name}
                </Typography>
              </Grid>
              <Divider className={classes.divider} variant="middle"/>
              <Typography
                  className={classes.description}
                  dangerouslySetInnerHTML={{ __html: product.description }}
                  variant={"body2"}
                  color={"textPrimary"}
              />
              <Grid item xs={12}>
                <Grid className={classes.prices} container>
                  <Grid item xs={4}>
                    <Typography
                      className={product.salePrice ? `${classes.strike} ${classes.price}` : classes.price}
                      variant={product.salePrice ? "body2" : "h4"}
                      align="center"
                      color={product.salePrice ? "error" : "secondary"}
                    >
                      {priceWithSymbol}
                    </Typography>
                    {
                        product.salePrice && (
                          <Typography variant={"h6"} align="center" color="secondary">
                            {salePriceWithSymbol}
                          </Typography>
                        )
                      }
                  </Grid>
                  <Grid item xs={8}>
                    <IconButton disabled={product.soldOut} onClick={product.inCart ? (e)=>{e.preventDefault(); setCartOpen(true)} : (e)=>{e.preventDefault(); product.inCart=true; addToCart(product)}} className={classes.addCart}>
                      {
                        product.inCart ? <ShoppingCart/> : !product.soldOut ? <AddShoppingCart/> : null
                      }
                      <Typography className={classes.cartButtonText} variant={"body2"} color={"textSecondary"}>
                         {!product.soldOut ? product.inCart ? "IN CART" : "ADD TO CART":"OUT OF STOCK"}
                      </Typography>
                    </IconButton>
                  </Grid>
                  {/* {product.salePrice && (
                    <Grid item xs={12}>
                      <Typography variant={"h6"} align="center">
                        {salePriceWithSymbol}
                      </Typography>
                    </Grid>
                  )} */}
                </Grid>
              </Grid>
            </Grid>
            </Link>
          </CardContent>
      </Card>
  );
};

export default ProductCard;
